import React from "react";
import Marquee from "react-fast-marquee";

export default function LineSlider() {
  const messages = [
    "REDEEM YOUR REWARDS FOR VOUCHERS",
    "EXCLUSIVE OFFERS AT YOUR FAVORITE BRANDS",
  ];
  return (
    <div className="relative my-[30px]">
      <div className="py-[9px] bg-[#FF8181]">
        <Marquee>
        {messages.map((message, index) => (
            <p
              key={index}
              className="relative font-neuemontrealmedium text-[12px] tracking-[1.2px] text-black pl-[28px] pr-[10px] ml-[20px] before:absolute before:left-[0px] before:h-[7px] before:w-[7px] before:rounded-full before:bg-black before:z-[2] before:top-[50%] before:translate-y-[-50%]"
            >
              {message}
            </p>
          ))}
        </Marquee>
      </div>
    </div>
  );
}
