import React from "react";
import bgEarth from "../../assets/images/bgEarth.png";
import DiscoverCard from "../../components/DiscoverPage/cards/DiscoverCard";
import Layout from "../Layout";

const Discover = () => {
  return (
    <>
      <Layout>
        <DiscoverCard />
        <img
          src={bgEarth}
          alt="bg-earth"
          className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-no-repeat z-[1] w-full max-w-[350px]"
        />
      </Layout>
    </>
  );
};

export default Discover;
