import React from 'react';
import LeaderComponent from '../../components/LeaderBoardComponent';
import Layout from '../Layout';

const LeaderBoard = () => {
  return (
    <Layout>
      <LeaderComponent />
    </Layout>
  )
}

export default LeaderBoard
