import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowDownRight } from "react-icons/go";
import KnowMore from "../../assets/images/know-more.svg";
import KnowMoreSpin from "../../assets/images/know-more-round.png";
import supabase from '../../supabaseClient';

const Banner = ({ scrollToDiscover }) => {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);
    
  useEffect(() => {
    if (window.location.href.includes('#') && session!==null ) { // Check if session exists
      navigate('/discover'); // Use navigate instead of window.location.href
    }
  }, [ session]); // Add session to the dependency array
  console.log('ses',session);

  return (
    <div className="banner-main banner-class-taken">
      <div className="container">
        <div className="banner-section pt-[60px] pb-[81px]">
          <div className="banner-title max-w-[270px] mx-auto mb-[72px]">
            <h1 className="font-neuemontrealmedium text-[22px] leading-[120%] text-white">
              World of
              <span className="block font-sspbold text-[60px] leading-[120%] text-gradient">
                SIXPENCE
              </span>
            </h1>
          </div>
          <div className="banner-btn text-center mb-[80px]">
            {session ? (
              <Link
                to="/discover"
                className="font-neuemontrealregular text-[22px] leading-[120%] text-black inline-flex justify-center items-center py-[6px] px-[14px] w-[165px] max-w-full bg-[#FEB3A4] rounded-[20px] hover:duration-300 hover:bg-white"
              >
                Enter <GoArrowDownRight className="ml-[3px]" />
              </Link>
              ) : (
              <Link
                to="/login"
                className="font-neuemontrealregular text-[22px] leading-[120%] text-black inline-flex justify-center items-center py-[6px] px-[14px] w-[165px] max-w-full bg-[#FEB3A4] rounded-[20px] hover:duration-300 hover:bg-white"
              >
                Enter <GoArrowDownRight className="ml-[3px]" />
              </Link>
            )}
          </div>
          <Link>
            <div  onClick={scrollToDiscover} className="banner-know-more relative w-[142px] h-[142px] rounded-full flex justify-center items-center mx-auto cursor-pointer">
              <div className="banner-know-img">
                <img src={KnowMore} alt="img" />
              </div>
              <div className="banner-know-text absolute w-full h-full animate-spin-slow">
                <img src={KnowMoreSpin} alt="img" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
