import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import google from "../../../assets/images/google.svg";
import Layout from "../../../pages/Layout";
export default function RewardLogin() {
  const location = useLocation();
  return (
    <>
      {/* Conditionally render content based on the current route */}
      {location.pathname === "/post/manage_reward" ? (
        <>
          {/* Main Post content here */}
          <div className="relative h-full w-full mx-auto flex justify-center items-center max-w-[330px]  pb-[131px]">
            <div className="container">
              <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] rounded-[30px] flex flex-col justify-center">
                <Link
                  to="/post/manage_reward/managerewardbtnlogin"
                  className="inline-block w-full"
                >
                  <div className="w-full bg-white p-2 rounded-[30px] flex items-center gap-1 font-neuemontrealmedium text-[14px] text-[#1f1f1f]">
                    <img
                      src={google}
                      alt="google"
                      className="h-[22px] w-[22px] mr-2"
                    />
                    <p>Continue with Google</p>
                  </div>
                </Link>
                <p className="text-white font-neuemontrealmedium text-[12px] leading-normal mt-[24px] text-start">
                  Your details stay private 🥷🏻. Log in to manage your posts and rewards.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Outlet />
      )}
    </>
  );
}
