import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import S11 from "../../assets/images/S11.png";
import fashion from "../../assets/images/fashion.png";
import S13 from "../../assets/images/S13.png";
import S14 from "../../assets/images/S14.png";
import S16 from "../../assets/images/S16.png";
import NoRewards from "../../assets/images/noreward.png";
import { useUser } from '../context/userContext'; // Assuming you have a user context
import supabase from "../../supabaseClient";
import { useParams } from 'react-router-dom';

export default function SliderOne({ selectedCategory }) {
  const [firstSlider, setFirstSlider] = useState([]);
  const { userData, userPosts, userId: loggedInUserId } = useUser();
  const [loading, setLoading] = useState(true);
  const { userId: routeUserId } = useParams();
  const userId = routeUserId || loggedInUserId;

  useEffect(() => {
    async function fetchPosts() {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('Posts')
          .select('*')
          .eq('user_id', userId)
          .order('created_at', { ascending: false });

        if (error) {
          console.error('Error fetching posts:', error);
        } else {
          const filteredRewards = getFilteredRewards(data);
          setFirstSlider(filteredRewards);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchPosts();
  }, [userId]); // Added userId to dependency array

  const getFilteredRewards = (posts) => {
    const rewardCategories = {
      food: { name: "Savorite", image: S11 },
      fashion: { name: "Vogue", image: fashion },
      lifestyle: { name: "Aventurine", image: S14 },
      tech: { name: "Obsidion", image: S13 },
      other: { name: "Luxion", image: S16 },
    };

    const rewardCounts = posts.reduce((acc, post) => {
      const category = post.category.toLowerCase();
      if (rewardCategories[category]) {
        acc[category] = (acc[category] || 0) + 1;
      }
      return acc;
    }, {});

    const filteredRewards = Object.entries(rewardCounts).map(([category, count]) => {
      const reward = rewardCategories[category];
      return {
        id: category,
        image: reward.image,
        title: reward.name,
        category: category,
        count: count,
      };
    });

    return filteredRewards;
  };

  const settings = {
    dots: false,
    infinite: firstSlider.length > 3,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(3, firstSlider.length),
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 25,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: Math.min(3, firstSlider.length),
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: Math.min(2, firstSlider.length),
        },
      },
      {
        breakpoint: 389,
        settings: {
          slidesToShow: Math.min(2, firstSlider.length),
        },
      },
    ],
  };

  const Slide = ({ children }) => (
    <div style={{ width: 'fit-content' }}>
      {children}
    </div>
  );

  return (
    <div className="relative">
      <div className="container pr-0">
        <div className="slider-container first-slider">
          {loading ? (
            <div className="flex h-[70vh] justify-center items-center">
              <p className="text-white">Loading...</p> 
            </div>
          ) : userData ? (
            firstSlider.length > 0 ? (
                <Slider {...settings}>
                  {firstSlider.map((reward) => (
                    <Slide key={reward.id}>
                      <div className="text-center block">
                        <div className="max-w-[80px] mb-[33px] mx-auto">
                          <img
                            src={reward.image}
                            alt={reward.title}
                            className="object-contain h-[100px] mx-auto"
                          />
                        </div>
                        <div className="w-full max-w-[120px] mx-auto">
                          <h4 className="text-white font-neuemontrealmedium text-[12px] leading-[120%] mb-[8px]">
                            {reward.title}
                          </h4>
                          <p className="text-white font-interReguler text-[10px] leading-[120%]">
                            Rewards: {reward.count}
                          </p>
                        </div>
                      </div>
                    </Slide>
                  ))}
                </Slider>
            ) : (
              <div className="container py-10">
                <img
                  src={NoRewards}
                  className="w-28 h-28"
                  alt="No rewards"
                />
                <p className="text-left text-white font-neuemontrealmedium text-[12px] leading-[120%]">
                  Share to unlock rewards
                </p>
              </div>
            )
          ) : (
            <div className="container py-10">
              <img
                src={NoRewards}
                className="w-28 h-28"
                alt="No rewards"
              />
              <p className="text-left text-white font-neuemontrealmedium text-[12px] leading-[120%]">
                Login and share to unlock
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
