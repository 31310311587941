import React, { useState, useEffect } from "react";
import ProfileCardList from "./ProfileCardList";
import { useParams } from "react-router-dom";
import { useUser } from "../context/userContext";
import ShareSomeThing from "./ShareSomeThing";
import LikedPosts from "./LikedPosts";
import supabase from '../../supabaseClient'; // Adjust the import path

export default function ProfilePage() {
  const [color, setColor] = useState(false);
  const { userId: loggedInUserId } = useUser();
  const [userPosts, setUserPosts] = useState([]);
  const { userId: routeUserId } = useParams();
  const userId = routeUserId || loggedInUserId;
  const [loader, setLoader] = useState(true);

  const handleToggleColor = () => {
    setColor((prevColor) => !prevColor);
  };

  const fetchCasts = async (userId) => {
    setLoader(true);
    const { data, error } = await supabase
      .from('Posts')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching casts:', error);
    } else {
      setUserPosts(data);
      setColor(false)
    }
  };
  useEffect(() => {
    if (userId) {
      fetchCasts(userId);
    }
  }, [userId]);

  return (
    userId && userId != null ? (
      <div className="relative pb-[131px]">
        <div className="container">
          <div className="fixed top-[121px] left-[50%] translate-x-[-50%] w-full max-w-[640px] pl-[30px] pr-[10px] z-10 bg-black flex justify-end mb-[30px]">
            <div
              className="inline-block cursor-pointer p-4 flex-shrink-0"
              onClick={handleToggleColor}
              style={{ lineHeight: 0 }}
            >
              {color ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ overflow: "visible" }}>      <path d="M15.7382 19.6309H6.26123L6.26123 11.8462H4.73816L5.24585 5.07701L6.26123 4.90778V3.72317L15.7382 3.72317V4.90778L16.9228 5.07701L17.2612 11.8462H15.7382V19.6309Z" fill="#3CBA41" />
                  <path d="M0 12.2222L0 9.77777H1.22222V8.55555H2.44444V7.33333H3.66667V6.11111H4.88889V4.88889H6.11111V3.66667H7.33333V2.44444H8.55555V1.22222H9.77778V0H12.2222V1.22222H13.4444V2.44444H14.6667V3.66667H15.8889V4.88889H17.1111V6.11111H18.3333V7.33333H19.5556V8.55556H20.7778V9.77778H22V12.2222H15.8889V22H6.11111V12.2222H0ZM4.88889 9.77778H8.55555V19.5556H13.4444V9.77778H17.1111V8.55555H15.8889V7.33333H14.6667V6.11111H13.4444V4.88889H12.2222V3.66667H9.77778V4.88889H8.55555V6.11111H7.33333V7.33333H6.11111V8.55555H4.88889V9.77778Z" fill="white" />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ overflow: "visible" }}>
                  <path d="M15.7382 19.6308H6.26123L6.26123 11.8462H4.73816L5.24585 5.07698L6.26123 4.90775V3.72313L15.7382 3.72313V4.90775L16.9228 5.07698L17.2612 11.8462H15.7382V19.6308Z" fill="black" />
                  <path stroke="FF8181" d="M0 12.2222L0 9.77777H1.22222V8.55555H2.44444V7.33333H3.66667V6.11111H4.88889V4.88889H6.11111V3.66667H7.33333V2.44444H8.55555V1.22222H9.77778V0H12.2222V1.22222H13.4444V2.44444H14.6667V3.66667H15.8889V4.88889H17.1111V6.11111H18.3333V7.33333H19.5556V8.55556H20.7778V9.77778H22V12.2222H15.8889V22H6.11111V12.2222H0ZM4.88889 9.77778H8.55555V19.5556H13.4444V9.77778H17.1111V8.55555H15.8889V7.33333H14.6667V6.11111H13.4444V4.88889H12.2222V3.66667H9.77778V4.88889H8.55555V6.11111H7.33333V7.33333H6.11111V8.55555H4.88889V9.77778Z" fill="white" />
                </svg>)}
            </div>
          </div>
          {color ? <LikedPosts setColor={setColor}/> : <ProfileCardList />}
        </div>
      </div>
    ) : (
      <ShareSomeThing />
    )
  );
}
