import React from "react";
import { Link,useLocation } from "react-router-dom";
import fashion from "../../assets/images/fashion.png";
import S11 from "../../assets/images/S11.png";
import S13 from "../../assets/images/S13.png";
import S14 from "../../assets/images/S14.png";
import S16 from "../../assets/images/S16.png";

export default function RewardWon({}) {
  const location = useLocation();
  const { category } = location.state || {}; // Extract category or set default value


  const getImageSrc = (category) => {
    switch (category) {
      case 'Fashion':
        return { src: fashion, name: 'Vogue' };
      case 'Food':
        return { src: S11, name: 'Savorite' };
      case 'Lifestyle':
        return { src: S14, name: 'Aventurine' };
      case 'Tech':
        return { src: S13, name: 'Obsidion' };
      case 'Other':
        return { src: S16, name: 'Luxion' };
      default:
        return ''; // Default image or empty string if no category matches
    }
  };

  // Get the image source based on the category
  const imageData = getImageSrc(category);

  return (
    <div className="bg-[black] mx-auto h-screen overflow-y-scroll w-full max-w-[640px] relative pt-[20px]">
      <div className="container">
        <div className="relative">
          <Link to="/">
            <h1 className="font-sspbold text-[22px] leading-[120%] text-gradient text-start">
              SIXPENCE
            </h1>
          </Link>
          {imageData.src && (
          <>
          <div className="h-[450px] flex justify-center items-center">
            {/* change the image as reward change */}
            <div className="w-full max-w-[130px] glow-container">
              <img
                src={imageData.src}
                alt="reward"
                className="object-cover glow-container"
              />
            </div>
          </div>
          <div className="mb-[71px]">
            <p className="text-white text-[18px] font-neuemontrealregular text-center">
              You’ve unlocked the&nbsp;
              <span className="text-[#EE7E8B] text-[18px] font-nohemiReguler">
                {imageData.name}
              </span>
            </p>
          </div>
          </>
          )}
          {/* button */}
          <div className="flex justify-center mb-2">
            <Link to="/reward">
              <button className="bg-[#EEEAE8] rounded-[30px] py-[10px] max-[320px]:px-[16px] px-[20px] font-neuemontrealmedium text-base leading-[120%]">
                Unlock amazing rewards with this 😍
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
