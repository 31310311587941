import React from "react";
import ProfilePage from "../../components/ProfilePage";
import Layout from "../Layout";

const Profile = () => {
  return (
    <Layout>
      <div className="h-full">
        <ProfilePage />
        {/* <ShareSomeThing /> */}
      </div>
    </Layout>
  );
};

export default Profile;
