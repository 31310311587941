import React from 'react';
import RewardedImg from '../../assets/images/rewared-1.png';
import DiscoverImg from '../../assets/images/discover-1.png';
import QRCode from '../../assets/images/QR-Code.png';
import SIXPENCE from '../../assets/images/SIXPENCE-1.png';

const QRCodePage = () => {
    return (
        <div className="bg-black min-h-screen flex flex-col items-center justify-center text-center text-white px-4">
            <p className="font-nohemiReguler text-[24px] text-center leading-relaxed mb-6 md:mb-10">
                "Our web app is designed for mobile use. Please<br />
                 scan the QR code with your phone to continue."
            </p>

            <div className="relative flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0">
                {/* Discover Image */}
                <div className="md:mr-4 w-[398.7px] h-[420px]">
                    <img
                        src={DiscoverImg}
                        alt="Discover"
                        className="w-full h-auto object-cover"
                    />
                </div>

                {/* QR Code */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[180px] h-[180px]">                    
                    <img
                        src={QRCode}
                        alt="QR Code"
                        className="w-full h-auto object-contain rounded-lg" />
                </div>

                {/* Rewarded Image */}
                <div className="md:ml-12 w-[398.7px] h-[420px]">
                    <img
                        src={RewardedImg}
                        alt="Rewarded"
                        className="w-full h-auto object-cover"
                    />
                </div>
            </div>
            <span className="block font-sspbold text-[60px] leading-[120%] text-gradient  mt-8 md:mt-12">
                SIXPENCE
            </span>
        </div>
    );
};

export default QRCodePage;
