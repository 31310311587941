import React,{useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import avatar2 from "../../assets/images/avatar2.svg";
import eye from "../../assets/images/eye.svg";
import like from "../../assets/images/like.svg";
import { useUser } from "../context/userContext";
import supabase from '../../supabaseClient'; // Adjust the import path
import { RandomAvatar } from "react-random-avatars";
import { useParams } from 'react-router-dom';


const Pageheader = () => {
  const location = useLocation();
  const isRewardPage = location.pathname.startsWith("/reward");
  const [totalUpvotes, setTotalUpvotes] = useState(0);
  const [userPosts, setUserPosts] = useState(0);
  const { userData,username ,userId: loggedInUserId } = useUser();
  const { userId: routeUserId } = useParams();
  const [useUsername, setUseUsername] = useState("")
  const userId = routeUserId || loggedInUserId;

  const fetchTotalUpvotes = async () => {
    const { data, error } = await supabase
      .from('Votes')
      .select('upvotes')
      .eq('creator_id', userId);

    if (error) {
      console.error('Error fetching total upvotes:', error);
    } else {
      const total = data.reduce((acc, vote) => acc + vote.upvotes, 0);
      setTotalUpvotes(total);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchTotalUpvotes();
    }
  }, [userId]);


  const fetchCasts = async () => {
    const { data, error } = await supabase
      .from('Posts')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching casts:', error);
    } else {
      setUseUsername(data[0]?.username)
      setUserPosts(data?.length);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchCasts();
    }
  }, [userId]);


  const renderProfileSection = () => (
    <>
        {userId ? (
            <Link to="/logout">
                <div className="flex gap-[8px] items-center">
                    <div className="bg-[#FFC1C1] rounded-full h-[26px] flex items-center justify-center">
                        <RandomAvatar name={username} size={24} />
                    </div>
                    <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                      {username}
                    </h4>
                </div>
            </Link>
        ) : (
            <Link to="/">
                <h1 className="font-sspbold text-[22px] leading-[120%] text-gradient">
                    SIXPENCE
                </h1>
            </Link>
        )}
        <Statistics userPosts={userPosts} totalUpvotes={totalUpvotes} />
    </>
);



const renderFollowerListSection = () =>{
return(
    <>
        <div className="rounded-full flex justify-center items-center h-[26px]">
      {/* {isCurrentUser ? <RandomAvatar name={username} size={24} /> : <RandomAvatar name={useUsername} size={24} />}            */}
      {useUsername && <RandomAvatar name={useUsername} key={Date.now()} size={24} />}
        <h2 className="text-white ml-2 text-[14px] leading-[120%] font-plusJakartaSemiBold">
          {useUsername && useUsername.length > 0 ? useUsername : username}
        </h2>
        </div>
        <Statistics userPosts={userPosts} totalUpvotes={totalUpvotes} />
    </>
);
}

const Statistics = ({ userPosts, totalUpvotes }) => {
  const displayUpvotes = userPosts === 0 ? 0 : totalUpvotes;

  return (
    <div className="flex flex-row ml-[100px] items-center gap-5 pt-[3px]">
      <div className="flex justify-end items-center gap-[9px]">
        <p className="text-[18px] font-nohemiReguler leading-[100%] text-white">
          {userPosts}
        </p>
        <Link to="/">
          <img src={eye} alt="eye" className="cursor-pointer" />
        </Link>
      </div>
      <div className="flex justify-end items-center gap-[9px]">
        <p className="text-[18px] font-nohemiReguler leading-[100%] text-white">
          {displayUpvotes}
        </p>
        <img src={like} alt="like" className="cursor-pointer" />
      </div>
    </div>
  );
};



  return (
    <>
      <div className="fixed w-full max-w-[640px] mx-auto z-50 bg-black pb-[20px] pt-[20px]">
        <div className="container">
        
        <div className="flex justify-between items-center mb-[28px]">
  {location.pathname === "/profile" ? (
    renderProfileSection() // Assuming renderProfileSection is a function that returns JSX
  ) : location.pathname.startsWith("/profile/") ? (
    renderFollowerListSection() // Assuming renderFollowerListSection is a function that returns JSX
  ) : (
    <Link to="/">
      <h1 className="font-sspbold text-[22px] leading-[120%] text-gradient">
        SIXPENCE
      </h1>
    </Link>
  )}
</div>

          {/* menus leaderboard / reward / profile */}
          <div className="realtive flex justify-between">
            <Link to="/leaderboard">
              <h4
                className={`text-[18px] font-[120%] font-neuemontrealregular hover:text-[#FF8181] duration-500 ${
                  location.pathname === "/leaderboard"
                    ? "text-[#FF8181]"
                    : "text-white"
                }`}
              >
                Leaderboard
              </h4>
            </Link>
            <Link to="/reward">
              <h4
                className={`text-[18px] font-[120%] font-neuemontrealregular hover:text-[#FF8181] duration-500 ${
                  isRewardPage ? "text-[#FF8181]" : "text-white"
                }`}
              >
                Rewards
              </h4>
            </Link>
            <Link to="/profile">
              <h4
                className={`text-[18px] font-[120%] font-neuemontrealregular hover:text-[#FF8181] duration-500 ${
                  location.pathname === "/profile"
                    ? "text-[#FF8181]"
                    : "text-white"
                }`}
              >
                Profile
              </h4>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pageheader;
