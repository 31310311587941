import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import supabase from '../../supabaseClient'; // Adjust the import path
import { useUser } from '../context/userContext'; // Assuming you have a user context
import { TailSpin } from 'react-loader-spinner';
import { RandomAvatar } from "react-random-avatars";
import ArrowDown from "../../assets/images/ArrowDown.svg";
import Arros from "../../assets/images/Arros.svg";
import ShareSomeThing from "./ShareSomeThing";

export default function ProfilecastList() {
  const [userPosts, setUserPosts] = useState([]);
  const [loader, setLoader] = useState(true);
  const { userData, userId: loggedInUserId } = useUser();
  const { userId: routeUserId } = useParams();
  const userId = routeUserId || loggedInUserId;
  const [disappearingCards, setDisappearingCards] = useState([]);
  const [postBeingDeleted, setPostBeingDeleted] = useState(null);

  const navigate = useNavigate();

  const fetchCasts = async (userId) => {
    setLoader(true);
    const { data, error } = await supabase
      .from('Posts')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching casts:', error);
    } else {
      setUserPosts(data);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (userId) {
      fetchCasts(userId);
    }
  }, [userId]);
  if (loader) {
    return (
      <div className="relative h-[70vh] flex justify-center items-center">
        <TailSpin height="40" width="90" color="#ffffff" />
      </div>
    );
  }

  async function upvotePost(postId, creatorId) {
    if (userData === "") {
      navigate('/post');
    }

    try {

      const { data: existingVote, error: existingVoteError } = await supabase
        .from('Votes')
        .select('*')
        .eq('post_id', postId)
        .eq('user_id', userData?.user.id)
        .single();

      if (existingVoteError && existingVoteError.code !== 'PGRST116') {
        console.error('Error fetching existing vote:', existingVoteError);
        return;
      }

      if (existingVote) {
        if (existingVote.downvotes > 0) {
          // Remove the downvote and add an upvote
          await supabase
            .from('Votes')
            .update({ downvotes: 0, upvotes: 1 })
            .eq('id', existingVote.id);
        } else {
          console.log('User already upvoted this post');
          return;
        }
      } else {
        await supabase
          .from('Votes')
          .insert([{ post_id: postId, user_id: userData?.user.id, upvotes: 1, downvotes: 0, creator_id: creatorId }]);
      }

    } catch (err) {
      console.error('Error upvoting post:', err);
    }
  }


  async function downvotePost(postId, creatorId) {

    try {
      const { data: existingVote, error: existingVoteError } = await supabase
        .from('Votes')
        .select('*')
        .eq('post_id', postId)
        .eq('user_id', userData?.user.id)
        .single();

      if (existingVoteError && existingVoteError.code !== 'PGRST116') {
        console.error('Error fetching existing vote:', existingVoteError);
        return;
      }

      if (existingVote) {

        if (existingVote.upvotes > 0) {
          // Remove the upvote and add a downvote
          await supabase
            .from('Votes')
            .update({ upvotes: 0, downvotes: 1 })
            .eq('id', existingVote.id);
        } else {
          console.log('User already downvoted this post');
          return;
        }
      } else {
        await supabase
          .from('Votes')
          .insert([{ post_id: postId, user_id: userData?.user.id, upvotes: 0, downvotes: 1, creator_id: creatorId }]);
      }
    } catch (err) {
      console.error('Error downvoting post:', err);
    }
  }

  const handleAnimationEnd = (cardId) => {
    setDisappearingCards((prev) => prev.filter((id) => id !== cardId));
  };

  async function deletePost(postId) {
    setPostBeingDeleted(postId);

    setTimeout(async () => {
      try {
        const { error } = await supabase
          .from('Posts')
          .delete()
          .eq('id', postId)
          .eq('user_id', loggedInUserId);

        await supabase
          .from('Votes')
          .update({ upvotes: 0 })
          .eq('post_id', postId);

        if (error) {
          console.error('Error deleting post:', error);
        }

        setUserPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));

      } catch (err) {
        console.error('Error deleting post:', err);
      }
    }, 600);
  }

  return (
    <div className="relative">
      {loader ? (
        <div className="flex h-[50vh] justify-center items-center">
          <TailSpin height="40" width="90" color="#ffffff" />
        </div>
      ) : userPosts.length > 0 ? (
            <div className="flex flex-col gap-5  mt-[60px]">
          {userPosts.map((cast) => (
            <div
              key={cast.id}
              className={`border-[1px] border-[#CAA] rounded-[20px] p-[18px] mb-[20px] pb-[0px] bg-black relative ${postBeingDeleted === cast.id ? 'animate-out' : ''
                }`}
              onAnimationEnd={() => handleAnimationEnd(cast.id)}
            >
              <div className="flex flex-row justify-between items-center mb-3">
                <div className="flex gap-[8px] items-center">
                  <div className="bg-[#FFC1C1] rounded-full h-[26px] w-[26px] flex items-center justify-center">
                    <RandomAvatar name={cast.username} size={24} />
                  </div>
                  <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                    {cast.username}
                  </h4>
                </div>
                <p className="font-plusJakartaMedium text-[12px] leading-[120%] text-[#ffffff70]">
                  {cast.category || 'No Category'}
                </p>
              </div>
              <div className="mb-[24px] last:mb-[0]">
                <h5 className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] mb-[11px]">
                  {cast.title}
                </h5>
                <p className="text-white text-[12px] font-plusJakartaMedium leading-[120%] mb-[24px]">
                  {cast.description}
                </p>
                {cast.image && (
                  <div className="rounded-[10px] overflow-hidden">
                    <img
                      src={cast.image}
                      alt="dish"
                      className="w-full mx-auto rounded-[10px] object-cover"
                    />
                  </div>
                )}
                {cast.user_id === loggedInUserId && (
                  <div className="flex">
                    <button
                      onClick={() => deletePost(cast.id)}
                      className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] ml-auto pt-[10px]"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <p className="text-start text-white font-neuemontrealregular text-[14px] leading-[130%]">
                {cast.content}
              </p>
              {routeUserId && (
                <div className="w-full pr-[35px] flex justify-between items-center mt-[10px] absolute bottom-[-13px]">
                  <div className="flex flex-row gap-[24px]">
                    <button onClick={() => upvotePost(cast.id, cast.user_id)} className="group">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1692 21.4154H6.83069L6.83069 12.9231H5.16915L5.723 5.53848L6.83069 5.35387V4.06156H17.1692V5.35387L18.4615 5.53848L18.8307 12.9231H17.1692V21.4154Z"
                          fill="#3CBA41"
                        />
                        <path
                          className="group-hover:fill-[#FFC00C]"
                          d="M-1.16564e-06 13.3333L-9.32509e-07 10.6667L1.33333 10.6667L1.33333 9.33333L2.66667 9.33333L2.66667 8L4 8L4 6.66667L5.33333 6.66667L5.33333 5.33333L6.66667 5.33333L6.66667 4L8 4L8 2.66667L9.33333 2.66667L9.33333 1.33333L10.6667 1.33333L10.6667 9.3251e-07L13.3333 1.16564e-06L13.3333 1.33333L14.6667 1.33333L14.6667 2.66667L16 2.66667L16 4L17.3333 4L17.3333 5.33333L18.6667 5.33333L18.6667 6.66667L20 6.66667L20 8L21.3333 8L21.3333 9.33333L22.6667 9.33333L22.6667 10.6667L24 10.6667L24 13.3333L17.3333 13.3333L17.3333 24L6.66666 24L6.66666 13.3333L-1.16564e-06 13.3333ZM5.33333 10.6667L9.33333 10.6667L9.33333 21.3333L14.6667 21.3333L14.6667 10.6667L18.6667 10.6667L18.6667 9.33333L17.3333 9.33333L17.3333 8L16 8L16 6.66667L14.6667 6.66667L14.6667 5.33333L13.3333 5.33333L13.3333 4L10.6667 4L10.6667 5.33333L9.33333 5.33333L9.33333 6.66667L8 6.66667L8 8L6.66667 8L6.66666 9.33333L5.33333 9.33333L5.33333 10.6667Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <button onClick={() => downvotePost(cast.id, cast.user_id)}>
                      <img
                        src={ArrowDown}
                        alt="ArrowDown"
                        className="h-[24px] w-[24px] hover:opacity-[0.7]"
                      />
                    </button>
                  </div>
                  {cast.product_url && cast.product_url.trim() !== '' && (
                    <div className="rounded-full bg-white border-[1px] border-[#CAA] h-[30px] w-[30px] p-1 flex items-center justify-center">
                      <button
                        onClick={() => {
                          let url = cast.product_url.trim();

                          // Remove any unwanted local paths or characters
                          url = url.replace(/^(http:\/\/|https:\/\/|www\.)/, ''); // Remove protocol and www if present
                          if (!/^http/.test(url)) {
                            url = 'http://' + url; // Add http if not present
                          }

                          // Regular expression to check if the URL is valid
                          const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);

                          if (isValidUrl) {
                            // Open the URL in a new tab
                            window.open(url, '_blank');
                          } else {
                            // Show an alert if the URL is not valid
                            alert('This is not a valid URL');
                          }
                        }}
                      >
                        <img
                          src={Arros}
                          alt="arros"
                          className="h-[24px] w-[24px]"
                        />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
            <div className="relative flex h-[70vh] justify-center items-center ">
              <div className="my-auto flex flex-col justify-center items-center fixed w-[100%] h-[70%]">
              <h2 className="text-[36px] text-white font-sdrregular text-center mb-1">
                <span className="font-sdrregular">Share</span> <span className="font-neuemontrealregular">something....</span>
              </h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                  className="w-[20%] h-[30%] absolute bottom-10 left-[58%] rotate-[269deg]">
                  <g fill="none" stroke="#FF8181" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                  <path d="m8 5l-5 5l5 5" />
                  <path d="M3 10h8c5.523 0 10 4.477 10 10v1" />
                </g>
              </svg>
          </div>
        </div>
      )}
    </div>
  );
}
