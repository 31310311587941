import React from "react";
import question from "../../assets/images/question.svg";
import { Link, Outlet } from "react-router-dom";
import SliderOne from "./SliderOne";
import LineSlider from "./LineSlider";
import ThirdSlider from "./ThirdSlider";

const RewardPage = () => {

  return (
    <>
      <div className="relative max-w-[640px] pb-[131px]">
        <div className="container">
            <div className="inline-block mb-[20px]">
              <Link to="rewardlist">
                <img
                  src={question}
                  alt="question"
                  className="h-[30px] w-[30px]"
                />
              </Link>
            </div>
        </div>
        <div className="flex flex-col">
          <SliderOne />
          <LineSlider/>
          <ThirdSlider />
        </div>
        <Outlet /> 
      </div>
    </>
  );
};

export default RewardPage;
