import React, { useEffect } from "react";
import { Auth } from '@supabase/auth-ui-react';
import supabase from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import SixpenceLogo from '../../assets/images/SIXPENCE-1.png';
import { useSession } from '../../context/SessionContext';

export default function LoginPage() {
  const { session, setSession } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [setSession]);


  return (
    <div className="bg-black min-h-screen flex flex-col">
      <img src={SixpenceLogo} alt="Sixpence Logo" className="w-[96px] h-[26px] ml-[20px] mt-[50px]" />
      <div className="flex-grow flex justify-center mt-[50%]">
        <div className="max-w-[330px] w-full mx-4">
          <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] rounded-[30px] flex flex-col justify-center">
            <div className="w-full bg-white p-2 rounded-[30px] flex justify-center items-center gap-1 font-neuemontrealmedium text-[14px] text-[#1f1f1f]">
              <Auth 
                supabaseClient={supabase} 
                providers={['google']} 
                redirectTo={`${window.location.origin}/discover`}
              />
            </div>
            <p className="text-white font-neuemontrealmedium text-[12px] leading-normal mt-[24px] text-start">
              Your details stay private 🥷🏻. Log in to manage your posts and rewards
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}