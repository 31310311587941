import React, { useState } from "react";
import Layout from "../../../pages/Layout";
import eye from "../../../assets/images/eye.svg";
import like from "../../../assets/images/like.svg";
import avatar2 from "../../../assets/images/avatar2.svg";


export default function OtherProfile() {
  const [color, setColor] = useState();
  const handleToggleColor = () => {
    setColor((prevColor) => !prevColor);
  };
  const profiles = [
    {
      id: 1,
      avatar: avatar2,
      name: "Pulasic",
      time: "3h",
      message:
        "Collect shimmering Aventurine gems by sharing your travel and adventure experiences.",
    },
  ];
  return (
    <Layout>
      <div className="relative">
        <div className="container">
          <div className="flex flex-row items-center justify-between mb-[30px] fixed top-[121px] left-[50%] translate-x-[-50%] w-full max-w-[640px] px-[30px] z-10 bg-black">
            <button onClick={handleToggleColor}
              className={`py-[7px] px-[28px] font-plusJakartaSemiBold rounded-[20px] border-[1px] border-[#caa] bg-${color ? "transparent text-white " : "[#FF8181] text-black border-transparent"}`}
            >
            {!color ? "Follow" : "Following" }
            </button>
            <div className="flex flex-row items-center gap-5">
              <div className="flex justify-end items-center gap-[9px]">
                <p className="text-[18px] font-nohemiReguler leading-[120%] text-white">
                  10.2k
                </p>
                  <img src={eye} alt="eye" className="cursor-pointer" />
              </div>
              <div className="flex justify-end items-center gap-[9px]">
                <p className="text-[18px] font-nohemiReguler leading-[120%] text-white">
                  10.2k
                </p>
                <img src={like} alt="like" className="cursor-pointer" />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 pb-[131px] pt-[70px]">
            {color ? (
              <>
                {profiles.map((profile) => (
                  <div
                    key={profile.id}
                    className="border-[1px] border-[#CAA] rounded-[20px] p-[18px] bg-black"
                  >
                    <div className="flex flex-row justify-between items-center mb-16">
                      <div className="flex flex-row items-center gap-2">
                        <div className="bg-white rounded-full flex justify-center items-center h-[26px] w-[26px]">
                          <img
                            src={profile.avatar}
                            alt="avatar"
                            className="h-[24px] w-[24px]"
                          />
                        </div>
                        <h2 className="text-white text-[14px] leading-[120%] font-plusJakartaSemiBold">
                          {profile.name}
                        </h2>
                      </div>
                      <p className="font-plusJakartaMedium text-[12px] leading-[120%] text-[#ffffff70]">
                        {profile.time}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
              {profiles.map((profile) => (
              <div
                key={profile.id}
                className="border-[1px] border-[#CAA] rounded-[20px] p-[18px] bg-black"
              >
                <div className="flex flex-row justify-between items-center mb-16">
                  <div className="flex flex-row items-center gap-2">
                    <div className="bg-white rounded-full flex justify-center items-center h-[26px] w-[26px]">
                      <img
                        src={profile.avatar}
                        alt="avatar"
                        className="h-[24px] w-[24px]"
                      />
                    </div>
                    <h2 className="text-white text-[14px] leading-[120%] font-plusJakartaSemiBold">
                      {profile.name}
                    </h2>
                  </div>
                  <p className="font-plusJakartaMedium text-[12px] leading-[120%] text-[#ffffff70]">
                    {profile.time}
                  </p>
                </div>
              </div>
            ))}</>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
