import React, { useRef } from 'react';
import DiscoverImg from '../../assets/images/discover-1.png';
import RewardedImg from '../../assets/images/rewared-1.png';
import TopArrow from '../../assets/images/top-arrow.svg';
import Header from '../../components/header';
import Banner from '../../components/banner';
import Discover from '../../components/discover';
import Rewarded from '../../components/rewarded';
import Description from '../../components/description';

const Home = ({onClick}) => {
 const discoverRef = useRef(null);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="home-main max-w-[640px] mx-auto">
                <div className="home-section bg-black min-h-[100vh]">
                    <Header />
                    <Banner scrollToDiscover={() => discoverRef.current.scrollIntoView({ behavior: 'smooth' })} />
                    <div ref={discoverRef}>
                    <Discover discover_title={'DISCOVER'} discover_text={'what the world is buying'} discover_img={DiscoverImg} />
                    </div>
                    <Rewarded rewarded_text={'Tell the world what you bought and get'} rewarded_title={'REWARDED'} rewared_img={RewardedImg} />
                    <Description />
                    <div className="scroll-top pt-[32px] pb-[32px]">
                        <div className="scroll-top-inside flex justify-center items-center w-[60px] h-[60px] rounded-full bg-[#FEB3A4] ml-auto mr-[28px] cursor-pointer hover:duration-300 hover:bg-white" onClick={scrollToTop}>
                            <img src={TopArrow} alt="img" />
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Home
