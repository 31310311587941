import React from 'react';;

const Discover = ({ discover_title, discover_text, discover_img }) => {
    return (
        <div className="discover-main pt-[40px] pb-[39px]">
            <div className="container">
                <div className="discover-section mb-[45px]">
                    <div className="discover-title">
                        <h3 className="font-neuemontrealmedium text-[36px] leading-[120%] text-white">{discover_title}</h3>
                    </div>
                    <div className="discover-text">
                        <p className="font-neuemontrealmedium text-[17px] leading-[120%] text-white">{discover_text}</p>
                    </div>
                </div>
            </div>
            <div className="discover-img max-w-[250px] max-h-[263px] ml-auto">
                <img src={discover_img} alt="img" className="object-cover max-h-full" />
            </div>
        </div>
    )
}

export default Discover
