import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import google from "../../../assets/images/google.svg";
import { Auth } from '@supabase/auth-ui-react';
import supabase from '../../../supabaseClient';

export default function PostManage() {
  const [session, setSession] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);


  useEffect(() => {
    if (session?.user && location.pathname === '/post') {
      navigate('/post/confirmation');
    }
  }, [session, location, navigate]);

  return (
    <>
      {/* Conditionally render content based on the current route */}
      {location.pathname === "/post" ? (
        loading ? (
          <div>Loading...</div> 
        ) : (
          session ? (
            <div className="relative h-full w-full mx-auto flex justify-center items-center max-w-[330px] pb-[131px]">
              <div className="container">
                <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] rounded-[30px] flex flex-col justify-center">
                  <p className="text-white font-neuemontrealmedium text-[12px] leading-normal mt-[24px] text-start">
                    Your details stay private 🥷🏻. Log in to manage your posts and rewards
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative h-full w-full mx-auto flex justify-center items-center max-w-[330px] pb-[131px]">
              <div className="container">
                <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] rounded-[30px] flex flex-col justify-center">
                  <div className="w-full bg-white p-2 rounded-[30px] flex justify-center items-center gap-1 font-neuemontrealmedium text-[14px] text-[#1f1f1f]">
                    <Auth supabaseClient={supabase} providers={['google']} />
                  </div>
                  <p className="text-white font-neuemontrealmedium text-[12px] leading-normal mt-[24px] text-start">
                    Your details stay private 🥷🏻. Log in to manage your posts and rewards
                  </p>
                </div>
              </div>
            </div>
          )
        )
      ) : (
        <Outlet />
      )}
    </>
  );
}
