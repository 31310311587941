import React from "react";
import { useParams } from "react-router-dom";
import cardData from "./discover";
import bgEarth from "../../../assets/images/bgEarth.png";
import ArrowDown from "../../../assets/images/ArrowDown.svg";
import ArrosWhite from "../../../assets/images/ArrosWhite.svg";
import Layout from "../../../pages/Layout";


const DiscoverDetail = () => {
  const { slug } = useParams();
  const card = cardData.find((c) => c.slug === slug);

  if (!card) {
    return <div>Crad not found</div>;
  }

  return (
    <Layout>
      <div className="relative z-10 pt-[30px]">
        <div className="container">
          <div className="px-[19px]">
            <div className="relative mb-[28px]">
              <div className="relative block bg-black border-[1px] border-[#CAA] rounded-[20px] p-[16px_16px_32px_16px]">
                <div className="flex justify-between items-center mb-[14px]">
                  <div className="flex gap-[8px] items-center">
                    <div className="bg-[#FFC1C1] rounded-full h-[26px] w-[26px] flex items-center justify-center">
                      <img
                        src={card.avatar}
                        alt="avatar"
                        className="h-[24px] w-[24px]"
                      />
                    </div>
                    <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                      {card.name}
                    </h4>
                  </div>
                  <p className="text-[#ffffff59] text-[12px] font-plusJakartaMedium leading-[120%]">
                    {card.time}
                  </p>
                </div>
                <div className="mb-[24px] last:mb-[0]">
                  <h5 className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] mb-[11px]">
                    {card.title}
                  </h5>
                  <p className="text-white text-[12px] font-plusJakartaMedium leading-[120%] mb-[24px]">
                    {card.description}
                  </p>
                  {card.image && (
                    <div className="rounded-[10px] overflow-hidden">
                      <img
                        src={card.image}
                        alt="dish"
                        className="w-full max-w-[253px] h-[161px] mx-auto rounded-[10px] object-cover"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full mt-[24px] px-[16px] relative flex justify-between items-center">
                <button>
                <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1692 21.4154H6.83069L6.83069 12.9231H5.16915L5.723 5.53848L6.83069 5.35387V4.06156H17.1692V5.35387L18.4615 5.53848L18.8307 12.9231H17.1692V21.4154Z"
                          fill="#3CBA41"
                        />
                        <path className="group-hover:fill-[#FFC00C]"
                          d="M-1.16564e-06 13.3333L-9.32509e-07 10.6667L1.33333 10.6667L1.33333 9.33333L2.66667 9.33333L2.66667 8L4 8L4 6.66667L5.33333 6.66667L5.33333 5.33333L6.66667 5.33333L6.66667 4L8 4L8 2.66667L9.33333 2.66667L9.33333 1.33333L10.6667 1.33333L10.6667 9.3251e-07L13.3333 1.16564e-06L13.3333 1.33333L14.6667 1.33333L14.6667 2.66667L16 2.66667L16 4L17.3333 4L17.3333 5.33333L18.6667 5.33333L18.6667 6.66667L20 6.66667L20 8L21.3333 8L21.3333 9.33333L22.6667 9.33333L22.6667 10.6667L24 10.6667L24 13.3333L17.3333 13.3333L17.3333 24L6.66666 24L6.66666 13.3333L-1.16564e-06 13.3333ZM5.33333 10.6667L9.33333 10.6667L9.33333 21.3333L14.6667 21.3333L14.6667 10.6667L18.6667 10.6667L18.6667 9.33333L17.3333 9.33333L17.3333 8L16 8L16 6.66667L14.6667 6.66667L14.6667 5.33333L13.3333 5.33333L13.3333 4L10.6667 4L10.6667 5.33333L9.33333 5.33333L9.33333 6.66667L8 6.66667L8 8L6.66667 8L6.66666 9.33333L5.33333 9.33333L5.33333 10.6667Z"
                          fill="white"
                        />
                      </svg>
                </button>
                <button>
                  <img
                    src={ArrosWhite}
                    alt="arros"
                    className="h-[30px] w-[30px]"
                  />
                </button>
                {/* unComment when need Link instead of icon */}
                {/* <p className="text-white font-neuemontrealmedium text-[18px] leading-[120%]">Get Link</p> */}
                <button>
                  <img
                    src={ArrowDown}
                    alt="ArrowDown"
                    className="h-[36px] w-[36px] hover:opacity-[0.7]"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={bgEarth}
        alt="bg-earth"
        className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-no-repeat z-[1] w-full max-w-[350px]"
      />
    </Layout>
  );
};

export default DiscoverDetail;
