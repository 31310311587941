import React from 'react';

const Rewarded = ({ rewarded_text, rewarded_title, rewared_img }) => {
    return (
        <div className="rewared-main pt-[39px] pb-[57px]">
            <div className="container">
                <div className="rewarded-section mb-[48px] max-w-[215px] ml-auto">
                    <div className="discover-text">
                        <p className="font-neuemontrealmedium text-[12.5px] leading-[120%] text-white">{rewarded_text}</p>
                    </div>
                    <div className="discover-title">
                        <h3 className="font-neuemontrealmedium text-[36px] leading-[120%] text-white tracking-[2px]">{rewarded_title}</h3>
                    </div>
                </div>
            </div>
            <div className="rewarded-img max-w-[250px] max-h-[263px]">
                <img src={rewared_img} alt="img" className="object-cover max-h-full" />
            </div>
        </div>
    )
}

export default Rewarded
