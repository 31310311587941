import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './components/context/userContext';
import QRCodePage from './components/WebPage/index';
import { isMobile } from 'react-device-detect';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      {isMobile ? (
        <App />
      ) : (
        <QRCodePage />
      )}
    </UserProvider>
  </React.StrictMode>
);
