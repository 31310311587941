import React from "react";
import { Outlet, useLocation } from "react-router-dom";
// import PostCreationPage from "../../components/PostCreationPage";
import Layout from "../Layout";
import PostManage from "../../components/PostCreationPage/managePost/PostManage";

const Post = () => {
  const location = useLocation();

  return (
    <Layout>
      {/* Conditionally render content based on the current route */}
      {location.pathname === "/post" ? (
        <>
          {/* Main Post content here */}
          {/* <PostCreationPage /> */}
          <PostManage />
          {/* Any other components or content for the main Post page */}
        </>
      ) : (
        <Outlet />
      )}
    </Layout>
  );
};

export default Post;
