import React, { useEffect, useState } from "react";
import ArrowDown from "../../../assets/images/ArrowDown.svg";
import Arros from "../../../assets/images/Arros.svg";
// import cardData from "./discover";
import { Link ,useNavigate} from "react-router-dom";
import supabase from '../../../supabaseClient';
import { useUser } from "../../context/userContext";
import { RandomAvatar } from "react-random-avatars";


const DiscoverCard = () => {
  const [cards, setCards] = useState([]);
  const [disappearingCards, setDisappearingCards] = useState([]);
  const [upvotedPosts, setUpvotedPosts] = useState([]);
  const { userData } = useUser();
  const navigate = useNavigate();

  async function viewPosts() {
    try {
      if (!userData) {
        const { data, error } = await supabase
          .from('Posts')
          .select('*')
          .order('created_at', { ascending: false });
        // console.log(data, 'discovercard')

        if (error) throw error;
        // Set the fetched posts to state
        setCards(data);
      } else {
        const userId = userData?.user?.id;

        if (userId) {
          // Fetch all posts
          const { data: allPosts, error: allPostsError } = await supabase
            .from('Posts')
            .select('*')
            .order('created_at', { ascending: false });

          if (allPostsError) throw allPostsError;

          // Fetch downvoted posts by the user
          const { data: downvoteData, error: downvoteError } = await supabase
            .from('Votes')
            .select('post_id, updated_at')
            .eq('user_id', userId)
            .eq('downvotes', 1)
            .order('updated_at', { ascending: true });

          if (downvoteError) throw downvoteError;

          // Create a Set of downvoted post IDs for quick lookup
          const downvotedPostIds = new Set(downvoteData.map(vote => vote.post_id));

          // Filter out downvoted posts from all posts
          const filteredPosts = allPosts.filter(post => !downvotedPostIds.has(post.id));

          // Fetch details of downvoted posts
          const downvotedPosts = allPosts.filter(post => downvotedPostIds.has(post.id));

          // Sort downvoted posts by `updated_at` from `downvoteData`
          const sortedDownvotedPosts = downvotedPosts.sort((a, b) => {
            const voteA = downvoteData.find(vote => vote.post_id === a.id);
            const voteB = downvoteData.find(vote => vote.post_id === b.id);
            return new Date(voteA.updated_at) - new Date(voteB.updated_at);
          });

          // Combine the filtered posts with the sorted downvoted posts
          const combinedPosts = [...filteredPosts, ...sortedDownvotedPosts];

          // Set the combined posts to state
          setCards(combinedPosts);
        }
      }
    } catch (err) {
      console.error('Error fetching posts:', err);
    }
  }

  useEffect(() => {
    viewPosts();
  }, [userData]);

  const fetchUserUpvotes = async (userId) => {
    const { data, error } = await supabase
      .from('Votes')
      .select('post_id')
      .eq('user_id', userId)
      .eq('upvotes', 1);
  
    if (error) {
      console.error('Error fetching user upvotes:', error);
      return [];
    }
    
    return data.map(vote => vote.post_id);
  };

  useEffect(() => {
    const userId = userData?.user?.id;
    if (userId) {
      fetchUserUpvotes(userId).then(setUpvotedPosts);
    }
  }, [userData]);

  async function upvotePost(postId, creatorId) {
    if(userData === "" || userData === null) {
      navigate('/post');
    }
    
    try {  
  
      const { data: existingVote, error: existingVoteError } = await supabase
        .from('Votes')
        .select('*')
        .eq('post_id', postId)
        .eq('user_id', userData?.user.id)
        .single();
  
      if (existingVoteError && existingVoteError.code !== 'PGRST116') {
        console.error('Error fetching existing vote:', existingVoteError);
        return;
      }
      const timestamp = new Date().toISOString().split('.')[0] + 'Z';
      if (existingVote) {
        if (existingVote.downvotes > 0) {
          setUpvotedPosts(prevState => [...prevState, postId]);

          // Remove the downvote and add an upvote
          await supabase
            .from('Votes')
            .update({ downvotes: 0, upvotes: 1, updated_at: timestamp })
            .eq('id', existingVote.id);
        } else {
          await supabase
            .from('Votes')
            .update({ updated_at: timestamp })
            .eq('id', existingVote.id);
          return;
        }
      } else {
            setUpvotedPosts(prevState => [...prevState, postId]);
        await supabase.from('Votes').insert([{ post_id: postId, user_id: userData?.user.id, upvotes: 1, downvotes: 0, creator_id: creatorId, updated_at: new Date() }]);
      
      }

  
    } catch (err) {
      console.error('Error upvoting post:', err);
    }
  }
  
  async function downvotePost(postId, creatorId) {
    try {  
      const { data: existingVote, error: existingVoteError } = await supabase
        .from('Votes')
        .select('*')
        .eq('post_id', postId)
        .eq('user_id', userData?.user.id)
        .single();
  
      if (existingVoteError && existingVoteError.code !== 'PGRST116') {
        console.error('Error fetching existing vote:', existingVoteError);
        return;
      }
      const timestamp = new Date().toISOString().split('.')[0] + 'Z';
      if (existingVote) {
        if (existingVote.upvotes > 0) {
          // Remove the upvote and add a downvote
          await supabase
            .from('Votes')
            .update({ upvotes: 0, downvotes: 1, updated_at: timestamp })
            .eq('id', existingVote.id);
        } else {
          await supabase
            .from('Votes')
            .update({ updated_at: timestamp })
            .eq('id', existingVote.id);
          return;
        }
      } else {
        await supabase
          .from('Votes')
          .insert([{ post_id: postId, user_id: userData?.user.id, upvotes: 0, downvotes: 1, creator_id: creatorId, updated_at: timestamp }]);
      }
  
    } catch (err) {
      console.error('Error downvoting post:', err);
    }

  }


  const handleArrowDownClick = (cardId, creatorId) => {
    // console.log('user data:', userData);
    setUpvotedPosts(prevState => prevState.includes(cardId) ? prevState.filter(id => id !== cardId) : [...prevState]);
      if(userData === "" || userData === null) {
      navigate('/post');
    }
    setDisappearingCards((prev) => [...prev, cardId]);
    downvotePost(cardId, creatorId);
  };

  const handleAnimationEnd = (cardId) => {
    // Move the card to the end of the list
    setCards((prev) => {
      const card = prev.find((c) => c.id === cardId);
      const remainingCards = prev.filter((c) => c.id !== cardId);
      return [...remainingCards, card];
    });
    // Remove the card from disappearingCards state
    setDisappearingCards((prev) => prev.filter((id) => id !== cardId));
  };

  return (
    <>
      <div className="relative z-10 pb-[131px]">
        <div className="fixed w-full max-w-[640px] h-[80px] left-[50%] translate-x-[-50%] top-[100px] bg-list-gradient z-[9]"></div>
        <div className="container">
          <div className="px-[19px] overflow-y-scroll pt-[50px]">
            {cards.map((card) => (

              <div
                key={card.id}
                className={`mb-[28px] ${
                  disappearingCards.includes(card.id) ? "animate-out" : ""
                }`}
                onAnimationEnd={() => handleAnimationEnd(card.id)}
              >
                <div className="relative block bg-black border-[1px] border-[#CAA] rounded-[20px] p-[16px_16px_24px_16px]">
                  <div className="flex justify-between items-center mb-[14px]">
                    <Link to={`/profile/${card?.user_id}`}>
                      <div className="flex gap-[8px] items-center">
                        <div className="bg-[#FFC1C1] rounded-full h-[26px] w-[26px] flex items-center justify-center">
                            <RandomAvatar name={card?.username} size={24} />
                        </div>
                        <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                          {card?.username}
                        </h4>
                      </div>
                    </Link>
                    <p className="text-[#ffffff59] text-[12px] font-plusJakartaMedium leading-[120%]">
                      {card.category}
                    </p>
                  </div>
                  <div className="mb-[24px] last:mb-[0]">
                    <h5 className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] mb-[11px]">
                      {card.title}
                    </h5>
                    <p className="text-white text-[12px] font-plusJakartaMedium leading-[120%] mb-[24px]">
                      {card.description}
                    </p>
                    {card.image && (
                      <div className="rounded-[10px] overflow-hidden">
                        <img
                          src={card.image}
                          alt="dish"
                          className="w-full mx-auto rounded-[10px] object-cover"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full mt-[-15px] px-[16px] relative flex justify-between items-center">
                  <div className="flex flex-row gap-[24px]">
                    <button onClick={()=> upvotePost(card.id, card.user_id)} className="group">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1692 21.4154H6.83069L6.83069 12.9231H5.16915L5.723 5.53848L6.83069 5.35387V4.06156H17.1692V5.35387L18.4615 5.53848L18.8307 12.9231H17.1692V21.4154Z"
                          fill="#3CBA41"
                        />
                        <path
                          className="group-hover:fill-[#FFC00C]"
                          d="M-1.16564e-06 13.3333L-9.32509e-07 10.6667L1.33333 10.6667L1.33333 9.33333L2.66667 9.33333L2.66667 8L4 8L4 6.66667L5.33333 6.66667L5.33333 5.33333L6.66667 5.33333L6.66667 4L8 4L8 2.66667L9.33333 2.66667L9.33333 1.33333L10.6667 1.33333L10.6667 9.3251e-07L13.3333 1.16564e-06L13.3333 1.33333L14.6667 1.33333L14.6667 2.66667L16 2.66667L16 4L17.3333 4L17.3333 5.33333L18.6667 5.33333L18.6667 6.66667L20 6.66667L20 8L21.3333 8L21.3333 9.33333L22.6667 9.33333L22.6667 10.6667L24 10.6667L24 13.3333L17.3333 13.3333L17.3333 24L6.66666 24L6.66666 13.3333L-1.16564e-06 13.3333ZM5.33333 10.6667L9.33333 10.6667L9.33333 21.3333L14.6667 21.3333L14.6667 10.6667L18.6667 10.6667L18.6667 9.33333L17.3333 9.33333L17.3333 8L16 8L16 6.66667L14.6667 6.66667L14.6667 5.33333L13.3333 5.33333L13.3333 4L10.6667 4L10.6667 5.33333L9.33333 5.33333L9.33333 6.66667L8 6.66667L8 8L6.66667 8L6.66666 9.33333L5.33333 9.33333L5.33333 10.6667Z"
                          fill={upvotedPosts.includes(card.id) ? "#FFC00C" : "white"} 

                        />
                      </svg>
                    </button>
                    <button onClick={() => handleArrowDownClick(card.id, card.user_id)}>
                      <img
                        src={ArrowDown}
                        alt="ArrowDown"
                        className="h-[24px] w-[24px]  hover:opacity-[0.7]"
                      />
                    </button>
                  </div>
                  {card.product_url && card.product_url.trim() !== '' &&
                    <div className="rounded-full bg-white border-[1px] border-[#CAA] h-[30px] w-[30px] p-1 flex items-center justify-center">
                      <button
                        onClick={() => {
                          let url = card.product_url.trim();

                          // Remove any unwanted local paths or characters
                          url = url.replace(/^(http:\/\/|https:\/\/|www\.)/, ''); // Remove protocol and www if present
                          if (!/^http/.test(url)) {
                            url = 'http://' + url; // Add http if not present
                          }

                          // Regular expression to check if the URL is valid
                          const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);

                          if (isValidUrl) {
                            // Open the URL in a new tab
                            window.open(url, '_blank');
                          } else {
                            // Show an alert if the URL is not valid
                            alert('This is not a valid URL');
                          }
                        }}
                      >
                        <img
                          src={Arros}
                          alt="arros"
                          className="h-[24px] w-[24px]"
                        />
                      </button>
                    </div>
                  }
                </div>                 
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverCard;
