import React, { useEffect, useRef, useState } from "react";
import avatar1 from "../../../assets/images/avatar1.svg";
import edit from "../../../assets/images/edit.svg";
import { Link, useLocation,useNavigate } from "react-router-dom";
import question from "../../../assets/images/question.svg";
import S15 from "../../../assets/images/S15.png";
import close from "../../../assets/images/close.svg";
import supabase from '../../../supabaseClient';
import { useUser } from "../../context/userContext";
import toast, { Toaster } from 'react-hot-toast';
import { TailSpin } from 'react-loader-spinner'



export default function ManagePostBtnLogin() {
  const fileInputRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const[ loader, setLoader ] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Lifestyle");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const { userData } = useUser();
  const navigate = useNavigate();




  const location = useLocation();
  const ReceiptUrl = location?.state?.url; // Decode the URL

  const handleQuestionClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAddMediaClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setSelectedImage(null);
    setImageFile(null);
  };

  const handleCategoryClick = (id, name) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === id
          ? { ...category, active: true }
          : { ...category, active: false }
      )
    );
    setSelectedCategory(name);
  };


  const handlePost = async () => {
    setLoader(true);
    if (!title ) {
      alert("Please enter a title.");
      setLoader(false);
      return;
    }

    let fileURL =""

    if(imageFile!=null) {

    const { data, error } = await supabase.storage
      .from('Products') // Replace 'Products' with your actual bucket name
      .upload(`images/${imageFile.name}`, imageFile); // Customize the path as needed

    if (error) {
      console.error('Error uploading file:', error);
      return;
    }
    

     fileURL = supabase.storage
      .from('Products')
      .getPublicUrl(`images/${imageFile.name}`);

    }

    try {
      const { data, error } = await supabase
        .from('Posts')
        .insert([
          {
            title: title,
            description:description,
            username:userData?.user?.identities[0].identity_data?.name,
            user_id: userData?.user.id,
            image: fileURL?.data?.publicUrl,
            product_url: link,
            category: selectedCategory,
            purchase_proof: ReceiptUrl
          },
        ]);

      if (error){
        toast.error("Failed to Post")
        throw error;
      };

      setLoader(false);
      toast.success('Successfully Posted!')

      if(ReceiptUrl!=null){
        navigate('/rewardwon', { state: { category: selectedCategory  } });
      }else{
        // navigate('/discover')
        navigate('/rewardwon', { state: { category: selectedCategory } });

      }

    } catch (err) {
      toast.error("Failed to Post")
      console.error('Error inserting data:', err);
    }
  };

  // Example category data
  const [categories, setCategories] = useState([
    { id: 1, name: "Lifestyle", active: true },
    { id: 2, name: "Food", active: false },
    { id: 3, name: "Tech", active: false },
    { id: 4, name: "Fashion", active: false },
    { id: 5, name: "Other", active: false },
  ]);


  return (
    <>
    <div><Toaster/></div>
      <div className="relative mt-3 pb-[131px]">
        <div className="container">
          <div className="h-[100%]">
            {/* introduction */}
            <div className="mb-2">
              <div className="flex items-center mb-[26px]">
                <div className="bg-[#9D3BFF] rounded-full flex justify-center items-center h-[45px] w-[45px] mr-3">
                  <img
                    src={avatar1}
                    alt="avatar"
                    className="h-[42px] w-[42px]"
                  />
                </div>
                <p className="text-white text-[18px] leading-[120%] font-plusJakartaSemiBold mr-2">
                  {userData?.user?.identities[0].identity_data?.full_name}
                </p>
              </div>
              {/* textarea */}
              <form className="w-full">
                <textarea
                  className="w-full bg-transparent focus:outline-none text-[#FF8181] text-[16px] leading-[120%] font-plusJakartaMedium placeholder-[#ff8181] mb-[38px]"
                  rows="2"
                  name="title"
                  id="title"
                  placeholder="Tell us, what did you buy?....*"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                >       
                </textarea>
                      <textarea
                  className="w-full bg-transparent focus:outline-none text-[16px] font-plusJakartaMedium leading-[120%] text-[#fff]"
                  rows="3"
                  name="caption"
                  id="caption"
                  placeholder="write any description...."
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </form>
            </div>
            {/* categories */}
            <div className="mb-[34px]">
              <h3 className="font-plusJakartaMedium text-sm leading-[120%] text-white mb-4">
                Select category:
              </h3>
              <ul className="flex flex-row justify-between">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    onClick={() => handleCategoryClick(category.id, category.name)}
                    className={`font-plusJakartaMedium text-xs leading-[120%] list-none cursor-pointer ${
                      category.active ? "text-[#FF8181]" : "text-white/60"
                    }`}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
            {/* add link */}
            <div className="flex flex-row items-center justify-center mx-auto gap-[22px] mb-7">
              <div className="relative w-full max-w-[230px]">
                <input
                  type="text"
                  name="link"
                  id="link"
                  className="w-full relative h-fit bg-transparent py-[8px] pr-6 pl-[96px] border-[2px] border-white rounded-[10px] text-white focus:outline-none"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
                <p className="font-plusJakartaMedium text-sm leading-[120%] text-white absolute left-[12px] top-[50%] translate-y-[-50%]">
                  Paste link:
                </p>
                <img
                  src={question}
                  alt="question"
                  className="w-4 h-4 absolute right-[8px] top-[50%] translate-y-[-50%] cursor-pointer"
                  onClick={handleQuestionClick}
                />
              </div>
              <span className="font-plusJakartaMedium textsm leading-[120%] text-white">
                for
              </span>
              <div className="w-[30px] h-[30px] overflow-hidden">
                <img
                  src={S15}
                  alt="item"
                  className="!h-[30px] !w-[30px] !object-cover"
                />
              </div>
            </div>
            {/* add media link or selected image */}
            <div className={`text-center ${!selectedImage ?  "mb-[28px]" : "mb-[28px]" }`}>
              {!selectedImage ? (
                <p
                  onClick={handleAddMediaClick}
                  className="text-white font-plusJakartaMedium text-[16px] leading-[120%] underline underline-offset-4 hover:text-[#FF8181] mt-[8%] duration-500 inline-block cursor-pointer"
                >
                  Add media
                </p>
              ) : (
                <div className="relative text-center mx-auto w-[150px] h-[100px]">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="mx-auto rounded-[20px] w-20 h-20 overflow-hidden"
                  />
                  <div
                    className="rounded-full flex items-center justify-center bg-black border-[1px] border-white w-[18px] h-[18px] absolute right-[-4px] top-[-4px] cursor-pointer"
                    onClick={handleCancelImage}
                  >
                    <img src={close} alt="close" className="h-[8px] w-[8px]" />
                  </div>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            {/* button */}
            <div className="flex justify-center mb-2 mt-[13%]">
              <button
                onClick={handlePost}
                className="bg-[#EEEAE8] rounded-[30px] py-[10px] max-[320px]:px-[20px] px-[28px] font-neuemontrealmedium text-base leading-[120%] hover:opacity-[0.7] duration-500"
              >
            {loader ?  <TailSpin height="20" width="90" color="#4fa94d"/>: "Post and get rewarded 🤩" }
            </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-full max-w-[640px] mx-auto">
          <div className="container">
            <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] bg-black rounded-[20px] flex flex-col justify-center">
              <button
                onClick={handleClosePopup}
                className="inline-block ml-auto -mt-3 mb-3"
              >
                <img src={close} alt="close" className="h-[14px] w-[14px]" />
              </button>
              <div>
                <p className="font-neuemontrealregular text-[18px] leading-[130%] text-white">
                  Get rewarded if you paste the link to the
                  product you bought, so that other users can also make the
                  purchase.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}