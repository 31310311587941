import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from './context/SessionContext';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from "./pages/home/Home";
import Discover from "./pages/Discover/index";
import DiscoverDetail from "./components/DiscoverPage/cards/DiscoverDetail";
import LeaderBoard from "./pages/LeaderBoard";
import Reward from "./pages/Reward";
import Profile from "./pages/Profile";
import RewardList from "./components/RewardPage/RewardList";
import ProfileCardList from "./components/ProfilePage/ProfileCardList";
import Post from "./pages/Post";
import RewardLogin from "./components/PostCreationPage/manageReward/RewardLogin";
import PostManage from "./components/PostCreationPage/managePost/PostManage";
import ManageRewardBtnLogin from "./components/PostCreationPage/manageReward/ManageRewardBtnLogin";
import ManagePostBtnLogin from "./components/PostCreationPage/managePost/ManagePostBtnLogin";
import RewardWon from "./components/RewardWon";
import ShareSomeThing from "./components/ProfilePage/ShareSomeThing";
import Image1 from "./components/RewardWon/Image1";
import Image2 from "./components/RewardWon/Image2";
import Image3 from "./components/RewardWon/Image3";
import Image5 from "./components/RewardWon/Image5";
import Image4 from "./components/RewardWon/Image4";
import ProfileFollowerList from "./components/ProfilePage/ProfileFollowerList";
import OtherProfile from "./components/ProfilePage/OtherProfile/OtherProfile";
import { createClient } from '@supabase/supabase-js';
import PostCreationPage from "./components/PostCreationPage";
import Logout from "./pages/Logout/Logout"
import LoginPage from "./components/LoginPage/LoginPage";

function App() {
  return (
    <SessionProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/discover" element={<Discover />} />
          <Route path="/discover/:slug" element={<DiscoverDetail />} />
          <Route exact path="/leaderboard" element={<LeaderBoard />} />

          {/* reward page route. */}
          <Route exact path="/reward/*" element={<Reward />}>
            <Route exact path="rewardlist" element={<RewardList />} />
          </Route>

          <Route exact path="/profile" element={<Profile />} />
          <Route path="/profile/:userId" element={<Profile/>} />
          <Route exact path="/profileCardlist" element={<ProfileCardList />} />
          <Route exact path="/followerlist" element={<ProfileFollowerList />} />
          <Route exact path="/share" element={<ShareSomeThing />} />
          <Route exact path="/otherprofile" element={<OtherProfile />} />

          {/* post and login screen route. */}
          <Route path="/post/*" element={<Post />}>
            <Route path="manage_reward" element={<RewardLogin />}>
              <Route
                exact
                path="managerewardbtnlogin"
                element={<ManageRewardBtnLogin />}
              />
            </Route>
            
            <Route path="manage_post" element={<PostManage />}>
              <Route
                exact
                path="managepostbtnlogin"
                element={<ManagePostBtnLogin />}
              />
            </Route>
            <Route path="confirmation" element={<PostCreationPage />}/>
          </Route>
          {/* <Route path="/login" element={<PostManage />}></Route> */}

          {/* <Route path="/post/*" element={<Post />} />
          <Route path="/manage_reward" element={<RewardLogin />} />
          <Route exact path="/confirmation" element={<Confirmation />} />
          <Route
            exact
            path="/managerewardbtnlogin"
            element={<ManageRewardBtnLogin />}
          />
          <Route path="/manage_post" element={<PostManage />} />
          <Route
            exact
            path="/managepostbtnlogin"
            element={<ManagePostBtnLogin />}
          /> */}

<Route path="login" element={<LoginPage />} />

          {/* reward winning screen */}
          <Route exact path="/rewardwon" element={<RewardWon />} />
          <Route exact path="/image1" element={<Image1 />} />
          <Route exact path="/image2" element={<Image2 />} />
          <Route exact path="/image3" element={<Image3 />} />
          <Route exact path="/image4" element={<Image4 />} />
          <Route exact path="/image5" element={<Image5 />} />
          <Route exact path="/logout" element={<Logout />} />

        </Routes>
      </Router>
    </SessionProvider>
  );
}

export default App;
