import React, { useState, useRef } from "react";
import Marquee from "react-fast-marquee";
import { Link,useNavigate } from "react-router-dom";
import arrowRight from "../../assets/images/arrowRight.svg";
import close from "../../assets/images/close.svg";
import { useUser } from "../context/userContext";
import supabase from "../../supabaseClient";
import toast, { Toaster } from 'react-hot-toast';
import { TailSpin } from 'react-loader-spinner'

const PostCreationPage = () => {
  const [show, setShow] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const[ loader, setLoader ] = useState(false);

  const { userData } = useUser();



  const slides = [
    "INSPIRE OTHERS",
    "GET REWARDED",
    "SHOWCASE YOUR LIFESTYLE",
    "INSPIRE OTHERS",
    "GET REWARDED",
    "SHOWCASE YOUR LIFESTYLE",
  ];

  const handleAddMediaClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      handlePost(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancelImage = () => {
    setSelectedImage(null);
    setImageFile(null);
  };


  const handlePost = async (file) => {
    setLoader(true);

    const { data, error } = await supabase.storage
      .from('Products') // Replace 'Products' with your actual bucket name
      .upload(`receipts/${file.name}`, file); // Customize the path as needed

    if (error) {
      console.error('Error uploading file:', error);
      return;
    }

    const fileURL = supabase.storage
      .from('Products')
      .getPublicUrl(`receipts/${file.name}`);

      // console.log('Uploading file:', fileURL);

      toast.success('Receipt Uploaded!')

      // setTimeout(() => {
        // const urlParameter = encodeURIComponent(fileURL); // Encode the URL to ensure it's safe to pass as a query parameter
        setLoader(false);
        navigate('/post/manage_post/managepostbtnlogin', { state: { url: fileURL.data.publicUrl } });
      // }, 3000);
    
    };

  


  return (
    <div className="flex flex-col h-[90%] items-center justify-center my-auto overflow-hidden pb-[131px] mt-5">
      {show ? (
        // "bro what" conformation pop up.
       <div className="container">
          <div className="border-[1px] border-[#CAA] py-[36px] px-[28px] rounded-[20px] flex flex-col justify-center">
            <button
              onClick={() => setShow(false)}
              className="inline-block ml-auto -mt-3 mb-3"
            >
              <img src={close} alt="close" className="h-[14px] w-[14px]" />
            </button>
            <div>
              <p className="font-neuemontrealregular text-[18px] leading-[130%] text-white">
                Could be a purchase confirmation mail from nike after buying new
                shoes or the Swiggy screenshot after placing an order for
                biryani. Anything that shows us that you actually made a
                purchase.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
        {/* main post page  */}
          <div className="container">
            <div className="block w-full">
              <p className="text-white text-center font-nohemiReguler text-[16px] tracking-[0.5px] leading-[140%] mb-3">
                Drop a link or a screenshot of your purchase to get rewarded 😎
              </p>
              <button onClick={() => setShow(true)} className="block ml-auto">
                <p className="font-nohemimedium text-[14px] leading-[120%] text-[#FF8181] underline underline-offset-2 underline-[#FF8181] text-end hover:opacity-[0.7] duration-500">
                  bro what ?
                </p>
              </button>
            </div>
          </div>
          <div className={`text-center ${!selectedImage ?  "mb-[28px]" : "mb-[28px]" }`}>
              {!selectedImage ? (
                <p
                  onClick={handleAddMediaClick}
                  className="text-white font-plusJakartaMedium text-[16px] leading-[120%] underline underline-offset-4 hover:text-[#FF8181] duration-500 inline-block cursor-pointer"
                >
                  Add media
                </p>
              ) : (
                <div className="relative text-center mx-auto w-[150px] h-[100px]">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="mx-auto rounded-[20px] w-20 h-20 overflow-hidden"
                  />
                  <div
                    className="rounded-full flex items-center justify-center bg-black border-[1px] border-white w-[18px] h-[18px] absolute right-[-4px] top-[-4px] cursor-pointer"
                    onClick={handleCancelImage}
                  >
                    <img src={close} alt="close" className="h-[8px] w-[8px]" />
                  </div>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            {loader ?  <TailSpin   height="40" width="90" color="#ffffff"/>:  

          <div className="flex justify-center mt-[30%] mb-2">
              <button onClick={()=>navigate('/post/manage_post/managepostbtnlogin')} className="bg-[#EEEAE8] rounded-[30px] py-[10px] max-[320px]:px-[20px] px-[28px] font-neuemontrealmedium text-base leading-[120%] hover:opacity-[0.7] duration-500">
                Skip
              </button>
          </div>
}
        </>
      )}
    </div>
  );
};

export default PostCreationPage;
