//give code to logout the user

import { useNavigate } from "react-router-dom";
import supabase from "../../supabaseClient";
import { useUser } from "../../components/context/userContext";
export default function Logout() {
  const navigate = useNavigate();
  const { setUserData } = useUser();

  async function logoutClick() {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUserData(null); // Reset user state
      navigate('/');
      window.location.reload();
    } else {
      console.error("Error signing out:", error);
    }
  }

  return (
    <div className="fixed w-full h-full flex justify-center items-center  max-w-[640px] mx-auto z-50 bg-black pb-[20px] pt-[20px]">
    <button onClick={logoutClick}
                  className="font-neuemontrealregular text-[16px] leading-[120%] text-black inline-flex justify-center items-center py-[6px] px-[14px] w-[165px] max-w-full bg-[#FEB3A4] rounded-[20px] hover:duration-300 hover:bg-white"
                  
       >Log out</button>
    </div>
  );
};