import React, { useEffect, useState } from "react";
import like from "../../assets/images/like.svg";
import { Link } from "react-router-dom";
import supabase from '../../supabaseClient';
import { RandomAvatar } from "react-random-avatars";
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { useUser } from '../context/userContext'; // Assuming you have a user context


export default function  LeaderComponent() {

  const [leaderboard, setLeaderboard] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { userData } = useUser();
  const[ currentUser, setCurrentUser ] = useState();


  let currentUserEntry = null;
   async function getLeaderboard() {
    try {
      setLoader(true);
      // Fetch votes
      const { data: votesData, error: votesError } = await supabase
        .from('Votes')
        .select('creator_id, upvotes')
        .order('upvotes', { ascending: false });

      if (votesError) throw votesError;

      // Aggregate upvotes by creator_id and filter out those with 0 or less upvotes
      const leaderboardData = votesData.reduce((acc, vote) => {
        if (!acc[vote.creator_id]) {
          acc[vote.creator_id] = {
            creator_id: vote.creator_id,
            total_upvotes: 0
          };
        }
        acc[vote.creator_id].total_upvotes += vote.upvotes;
        return acc;
      }, {});

      // Convert to array, filter out entries with 0 or less upvotes, and sort
      const leaderboardArray = Object.values(leaderboardData)
        .filter(entry => entry.total_upvotes > 0)
        .sort((a, b) => b.total_upvotes - a.total_upvotes);

      // Fetch profile information only for creators with upvotes > 0
      const { data: profilesData, error: profilesError } = await supabase
        .from('profiles')
        .select('id, display_name, email, created_at')
        .in('id', leaderboardArray.map(entry => entry.creator_id));

      if (profilesError) throw profilesError;

      // Merge profile data with leaderboard data
      const finalLeaderboard = leaderboardArray.map((entry, index) => {
        const profile = profilesData.find(p => p.id === entry.creator_id);
        return {
          ...entry,
          index: index + 1,
          display_name: profile?.display_name || 'Anonymous',
          email: profile?.email,
          created_at: profile?.created_at
        };
      });

      // Find current user's entry
      const currentUserEntry = finalLeaderboard.find(entry => entry.creator_id === userData?.user?.id);
      setCurrentUser(currentUserEntry);

      setLoader(false);
      return finalLeaderboard;
    } catch (err) {
      console.error('Error fetching leaderboard:', err);
      setLoader(false);
      return [];
    }
   }


useEffect(() => {
  getLeaderboard().then(leaderboard => {
    setLeaderboard(leaderboard);
  });
}, []);

const handleProfileClick = (userId) => {
  navigate(`/profile/${userId}`);
};
  return (
    <>
      <div className="relative pb-[131px]">
        <div className="container px-[20px]">
          <div className="w-full">
          {loader ? (
          <div className="flex h-[50vh] justify-center items-center">
            <TailSpin height="40" width="90" color="#ffffff" />
          </div>
        ) : (
          <>
            {leaderboard.map((leader, index) => (
              <div
                key={index}            
                className={`relative mb-[28px] `}
              >
                <div
                  onClick={() => handleProfileClick(leader.creator_id)}
                  style={{ backgroundColor: leader.creator_id === userData?.user?.id ? '#964B00' : '#000000' }}
                  className={`relative mt-5 p-[8px_14px] border-[1px] border-white  z-[5] cursor-pointer`}

                >
                  <div className="flex flex-row justify-start items-center">
                    <h2 className="w-1/12 text-[18px] font-neuemontregular leading-[120%] text-white">
                      #{leader.index}
                    </h2>
                    <div className="w-2/12">
                      <div className="mx-auto bg-[#9D3BFF] rounded-full flex justify-center items-center h-[26px] w-[26px]">     
                         <RandomAvatar name={leader.display_name} size={24} />
                      </div>
                    </div>
                    <h3 className="w-3/12 text-[18px] font-neuemontmedium leading-[120%] text-white">
                      {leader.display_name}
                    </h3>
                    <div className="w-[40%] flex justify-end items-center gap-[9px]">
                      <p className="text-[14px] font-nohemiReguler leading-[120%] text-white">
                        {leader.total_upvotes}
                      </p>
                      <img src={like} alt="like" className="w-[16px] h-[16px]" />
                    </div>
                  </div>
                </div>
                <div className="absolute top-[3px] left-[2px] h-full w-full p-[8px_14px] border-[1px] border-white z-[1]"></div>
                <div className="absolute top-[6px] left-[4px] h-full w-full p-[8px_14px] border-[1px] border-white z-[3]"></div>
              </div>
            ))}
          </>
        )}
          </div>
        </div>
      </div>
    </>
  );
}