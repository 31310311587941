import React from 'react';
import Globe from '../../assets/images/globe.svg';
import Dollar from '../../assets/images/Dollar.svg'

const Description = () => {
    return (
        <div className="des-main pt-[57px] pb-[33px]">
            <div className="container">
                <div className="des-section px-[10px]">
                    <div className="des-text mb-[45px]">
                        <p className="font-nohemimedium text-[22px] leading-[200%] text-white">
                            This game is simple, discover what people around the world are buying by clicking on <img src={Globe} alt="img" className="inline-block" /> .
                        </p>
                    </div>
                    <div className="des-or mb-[58px] text-center">
                        <p className="font-neuemontrealmedium text-[26px] leading-[120%] text-[#FEB3A4]">OR</p>
                    </div>
                    <div className="des-text">
                        <p className="font-nohemimedium text-[22px] leading-[200%] text-white">
                            Get rewarded <img src={Dollar} alt="img" className="inline-block" /> for telling the world about your purchases, by posting them here.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description
