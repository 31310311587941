import React from "react";
import Slider from "react-slick";
import TS1 from "../../assets/images/TS1.png";
import TS2 from "../../assets/images/TS2.png";
import Rewards from "../../assets/images/rewards.png";

export default function ThirdSlider() {
  const ThirdSlides = [
    {
      image: TS1,
      altText: "500 Rupee Swiggy Voucher",
      description: "500 Rupee Swiggy Voucher",
      buttonText: "2 Savorite",
    },
    {
      image: TS2,
      altText: "1000 Rupee Amazon Voucher",
      description: "1000 Rupee Amazon Voucher",
      buttonText: "2 Vogue",
    },
    {
      image: TS1,
      altText: "500 Rupee Swiggy Voucher",
      description: "500 Rupee Swiggy Voucher",
      buttonText: "2 Savorite",
    },
    {
      image: TS2,
      altText: "1000 Rupee Amazon Voucher",
      description: "1000 Rupee Amazon Voucher",
      buttonText: "2 Vogue",
    },
    {
      image: TS1,
      altText: "500 Rupee Swiggy Voucher",
      description: "500 Rupee Swiggy Voucher",
      buttonText: "2 Savorite",
    },
    {
      image: TS2,
      altText: "1000 Rupee Amazon Voucher",
      description: "1000 Rupee Amazon Voucher",
      buttonText: "2 Vogue",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 389,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="relative">
      <div className="container pl-[10px] pr-[10px]">
        <div className="third-slider-main">
          <div className="slider-container">
          <img
                src={Rewards}
                alt="question"
              />

          </div>
        </div>
      </div>
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none" }}
      onClick={onClick}
    >
      <button className="cursor-pointer group"> 
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="19"
          viewBox="0 0 25 19"
          fill="none"
        >
          <path className="group-hover:fill-[#ff8181] duration-500"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.5734 8.55627C24.7661 8.74913 24.8743 9.01057 24.8743 9.28315C24.8743 9.55573 24.7661 9.81717 24.5734 10.01L16.3446 18.2389C16.2504 18.34 16.1368 18.421 16.0107 18.4773C15.8845 18.5335 15.7483 18.5637 15.6102 18.5661C15.4721 18.5686 15.3349 18.5432 15.2068 18.4914C15.0787 18.4397 14.9624 18.3627 14.8647 18.265C14.767 18.1674 14.69 18.051 14.6383 17.9229C14.5865 17.7948 14.5611 17.6577 14.5636 17.5195C14.566 17.3814 14.5962 17.2452 14.6525 17.119C14.7087 16.9929 14.7897 16.8793 14.8908 16.7851L21.3642 10.3118L1.90288 10.3118C1.63007 10.3118 1.36844 10.2034 1.17554 10.0105C0.982637 9.81758 0.874268 9.55595 0.874268 9.28315C0.874268 9.01035 0.982637 8.74872 1.17554 8.55581C1.36844 8.36291 1.63007 8.25454 1.90288 8.25454L21.3642 8.25454L14.8908 1.78115C14.7897 1.68698 14.7087 1.57342 14.6525 1.44725C14.5962 1.32107 14.566 1.18487 14.5636 1.04676C14.5611 0.908644 14.5865 0.771456 14.6383 0.643376C14.69 0.515296 14.767 0.398948 14.8647 0.301273C14.9624 0.203598 15.0787 0.126596 15.2068 0.0748627C15.3349 0.0231291 15.4721 -0.00227674 15.6102 0.000160083C15.7483 0.00259691 15.8845 0.0328264 16.0107 0.0890461C16.1368 0.145266 16.2504 0.226323 16.3446 0.327383L24.5734 8.55627Z"
            fill="#F8F8F8"
          />
        </svg>
      </button>
    </div>
  );
}
