import avatar3 from "../../../assets/images/avatar3.svg";
import dish from "../../../assets/images/dish.png";
import food2 from "../../../assets/images/food2.png";
import food3 from "../../../assets/images/food3.png";

const cardData = [
    {
      id: 1,
      avatar: avatar3,
      name: "Pulasic",
      time: "3hr",
      title: "Bought tickets to Ed Sheeran’s concert on bookmyshow.",
      description: "Catch me in the fan pit on the 16th",
      image: "",
      slug: "discover-1"
    },
    {
      id: 2,
      avatar: avatar3,
      name: "Pulasic",
      time: "3hr",
      title: "Bought food at El Paston, Hi-Tech city.",
      description: "Never been a fan of Mexican but this meal might have changed that.",
      image: dish,
      slug: "discover-2"
    },
    {
      id: 3,
      avatar: avatar3,
      name: "Pulasic",
      time: "3hr",
      title: "Bought tickets to Ed Sheeran’s concert on bookmyshow.",
      description: "Catch me in the fan pit on the 16th",
      image: "",
      slug: "discover-3"
    },
    {
      id: 4,
      avatar: avatar3,
      name: "Pulasic",
      time: "3hr",
      title: "Bought tickets to Ed Sheeran’s concert on bookmyshow.",
      description: "Catch me in the fan pit on the 16th",
      image: food2,
      slug: "discover-4"
    },
    {
      id: 5,
      avatar: avatar3,
      name: "Pulasic",
      time: "3hr",
      title: "Bought tickets to Ed Sheeran’s concert on bookmyshow.",
      description: "Catch me in the fan pit on the 16th",
      image: food3,
      slug: "discover-5"
    }
]

export default cardData;