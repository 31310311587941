import React from "react";
import S11 from "../../assets/images/S11.png";
import fashion from "../../assets/images/fashion.png";
import S13 from "../../assets/images/S13.png";
import S14 from "../../assets/images/S14.png";
import S16 from "../../assets/images/S16.png";
import S15 from "../../assets/images/S15.png";
import ArrowBack from "../../assets/images/ArrowBack.svg";
import { Link } from "react-router-dom";
export default function RewardList() {
  const rewards = [
    {
      id: 1,
      image: fashion,
      title: "Vogue",
      description:
        "Earn radiant Vogue gems by posting your fashion purchases and sharing your unique style.",
    },
    {
      id: 2,
      image: S13,
      title: "Obsidion",
      description:
        "Unlock sleek Obsidian gems by posting about your latest tech purchases and innovations.",
    },
    {
      id: 6,
      image: S14,
      title: "Aventurine",
      description:
        "Collect shimmering Aventurine gems by sharing your travel and adventure experiences.",
    },
    {
      id: 3,
      image: S11,
      title: "Savorite",
      description:
        "Receive vibrant Savorite gems by sharing your food-related purchases and culinary delights.",
    },
    {
      id: 4,
      image: S16,
      title: "Luxion",
      description:
        "Get Luxion for sharing those purchases that don’t fall in any of the categories.",
    },
    {
      id: 5,
      image: S15,
      title: "Portalix",
      description:
        "Get the Portalix gem for providing a portal (link to the product you bought) so that other users can replicate your purchase.",
    },
    // Add more rewards as needed
  ];

  return (
    <>
      <div className="relative pb-[131px]">
        <div className="container">
          <div className="inline-block mb-[20px]">
            <Link to="/reward">
              <img
                src={ArrowBack}
                alt="question"
                className="h-[30px] w-[30px]"
              />
            </Link>
          </div>
          <div className="flex flex-col gap-10 overflow-y-scroll">
            {rewards.map((reward) => (
              <div key={reward.id} className="flex flex-row gap-[20px]">
                <div className="w-full max-w-[100px]">
                  <img
                    src={reward.image}
                    alt={reward.title}
                    className="object-contain max-w-full w-full h-[120px]"
                  />
                </div>
                <div className="w-full flex flex-col justify-center">
                  <h4 className="text-start text-white font-nohemiReguler text-[20px] leading-[130%] mb-[8px]">
                    {reward.title}
                  </h4>
                  <p className="text-start text-white font-neuemontrealregular text-[14px] leading-[130%]">
                    {reward.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
