import React from "react";
import Pageheader from "../components/PageHeader";
import Twobtn from "../components/Twobtn";

export default function Layout({ children }) {
  return (
    <div className="relative overflow-hidden h-dvh bg-black w-full max-w-[640px] pb-[16px] mx-auto">
      <Pageheader />
      <div className="height-layout overflow-y-auto mt-[121px]">{children}</div>
      <Twobtn />
    </div>
  );
}
