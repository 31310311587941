import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/images/closeicon.svg';
import menuIcon from '../../assets/images/menuicon.svg';

const Header = () => {
    const [click, setClick] = useState(false);
    const [step, setStep] = useState('step-header-1');

    const handleClick = () => {
        setClick(!click);
        document.body.style.overflow = click ? 'auto' : 'hidden';
    };

    const closeMobileMenu = () => {
        setClick(false);
        document.body.style.overflow = 'auto';
    };

    const switchToStep2 = () => {
        setStep('step-header-2');
        setClick(false);
        document.body.style.overflow = 'hidden';
    };

    const switchToStep3 = () => {
        setStep('step-header-3');
        setClick(false);
        document.body.style.overflow = 'hidden';
    };

    const switchToStep4 = () => {
        setStep('step-header-4');
        setClick(false);
        document.body.style.overflow = 'hidden';
    };

    const handleStepClose = () => {
        // alert('step-2-click');
        setStep('step-header-1');
        setClick(true);
        document.body.style.overflow = 'hidden';
    };

    return (
        <>
            {step === 'step-header-1' && (
                <header className={`header ${click ? 'bg-[#EEEAE8]' : 'bg-black'}` }>
                    <div className="container">
                            <div className="header-inside flex justify-between items-center pt-[58px] pb-[10px] step-header-1">
                                <Link
                                    to="/"
                                    className="navbar-logo-link inline-block mr-[15px]"
                                    onClick={closeMobileMenu}
                                >
                                    <h1 className="font-neuemontrealregular text-[22px] leading-[120%]">SIXPENCE</h1>
                                </Link>
                                <div
                                    className={`navbar-toggler cursor-pointer text-[30px] text-[#0061aa] w-[36px] h-[36px] rounded-full inline-flex justify-center items-center ${click ? 'bg-[#1E1E1E]' : 'bg-[#FFFFFF]'}`}
                                    onClick={handleClick}
                                >
                                    {click ? <img src={closeIcon} alt="Close" /> : <img src={menuIcon} alt="Menu" />}
                                </div>
                                <div
                                    className={`navbar w-[101%] fixed top-[95px] transition-all pt-[100px] pb-[29px] height-90 z-[99] bg-[#EEEAE8] max-w-[640px] translate-x-[-50%] flex flex-col justify-between ${
                                        click ? 'right-[unset] left-[50%] translate-x-[-50%]' : 'right-[-200%] left-auto translate-x-0'
                                    } overflow-y-auto`}
                                >
                                    <ul className="navbar-nav flex items-center flex-col">
                                        <li className="nav-item pb-[69px]">
                                            <Link to="/" className="inline-flex justify-center nav-link font-sdrregular text-[48px] leading-[100%] text-black hover:opacity-50 hover:duration-300" onClick={switchToStep2}>
                                                <span className="font-neuemontrealregular text-[18px] leading-[180%] text-black mr-[14px]">01</span>What
                                            </Link>
                                        </li>
                                        <li className="nav-item pb-[69px]">
                                            <Link to="/" className="inline-flex justify-center nav-link font-sdrregular text-[48px] leading-[100%] text-black hover:opacity-50 hover:duration-300" onClick={switchToStep3}>
                                                <span className="font-neuemontrealregular text-[18px] leading-[180%] text-black mr-[14px]">02</span>Why
                                            </Link>
                                        </li>
                                        <li className="nav-item pb-[69px]">
                                            <Link to="/" className="inline-flex justify-center nav-link font-sdrregular text-[48px] leading-[100%] text-black hover:opacity-50 hover:duration-300" onClick={switchToStep4}>
                                                <span className="font-neuemontrealregular text-[18px] leading-[180%] text-black mr-[14px]">03</span>When
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="navbar-mail-div text-center">
                                        <Link to="mailto:careers@getsixpence.com" className="font-neuemontrealregular text-[18px] leading-[120%] text-black hover:opacity-50 hover:duration-300">careers@getsixpence.com</Link>
                                    </div>
                                </div>
                            </div>
                    </div>
                </header>
            )}
            {step === 'step-header-2' && (
                <header className={`header bg-[#EEEAE8]`}>
                    <div className="container">
                        <div className="header-inside flex justify-between items-center pt-[58px] pb-[10px] step-header-2">
                            <Link
                                to="/"
                                className="navbar-logo-link inline-block mr-[15px]"
                                onClick={handleStepClose}
                            >
                                    <h1 className="font-neuemontrealregular text-[22px] leading-[120%]">SIXPENCE</h1>
                               </Link>
                            <div
                                className={`navbar-toggler cursor-pointer text-[30px] text-[#0061aa] w-[36px] h-[36px] rounded-full inline-flex justify-center items-center bg-[#1E1E1E]`}
                                onClick={handleStepClose}
                            >
                                <img src={closeIcon} alt="Close" />
                            </div>
                            <div
                                className={`navbar w-[101%] fixed top-[95px] transition-all pt-[50px] pb-[29px] px-[30px] height-90 z-[99] bg-[#EEEAE8] max-w-[640px] flex flex-col justify-between right-[unset] left-[50%] translate-x-[-50%] overflow-y-auto`}
                            >
                                <div className="wh-question-start">
                                    <div className="wh-question-title mb-[57px] min-h-[74px]">
                                        <h2 className="font-sdrregular text-[28px] leading-[37px] text-black">What is Sixpence?</h2>
                                    </div>
                                    <div className="wh-question-text mb-[20px]">
                                        <p className="font-sdrregular text-[18px] leading-[24px] text-black">Sixpence (mobile app) is a social commerce platform where people can share their purchases as social posts and get rewarded for doing so. People on the platform can discover what people they know and people around the world are buying and replicate their lifestyles and experiences by replicating their purchases.</p>
                                    </div>
                                </div>
                                <div className="navbar-mail-div text-center">
                                    <Link to="mailto:careers@getsixpence.com" className="font-neuemontrealregular text-[18px] leading-[120%] text-black hover:opacity-50 hover:duration-300">careers@getsixpence.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
            {step === 'step-header-3' && (
                <header className={`header bg-[#EEEAE8]`}>
                    <div className="container">
                        <div className="header-inside flex justify-between items-center pt-[58px] pb-[10px] step-header-2">
                            <Link
                                to="/"
                                className="navbar-logo-link inline-block mr-[15px]"
                                onClick={handleStepClose}
                            >
                                 <h1 className="font-neuemontrealregular text-[22px] leading-[120%]">SIXPENCE</h1>
                               </Link>
                            <div
                                className={`navbar-toggler cursor-pointer text-[30px] text-[#0061aa] w-[36px] h-[36px] rounded-full inline-flex justify-center items-center bg-[#1E1E1E]`}
                                onClick={handleStepClose}
                            >
                                <img src={closeIcon} alt="Close" />
                            </div>
                            <div
                                className={`navbar w-[101%] fixed top-[95px] transition-all pt-[50px] pb-[29px] px-[30px] height-90 z-[99] bg-[#EEEAE8] max-w-[640px] flex flex-col justify-between right-[unset] left-[50%] translate-x-[-50%] overflow-y-auto`}
                            >
                                <div className="wh-question-start">
                                    <div className="wh-question-title mb-[57px] min-h-[74px]">
                                        <h2 className="font-sdrregular text-[28px] leading-[37px] text-black">Why did you develop this web application ?</h2>
                                    </div>
                                    <div className="wh-question-text mb-[20px]">
                                        <p className="font-sdrregular text-[18px] leading-[24px] text-black">We made this web app to build our initial community of users. This web app is quite different from the mobile app which will be coming out soon, which will be more social and interactive. Our goal is to introduce and make users familiar with our new and innovative approach to social commerce and product discovery through this web app before we launch our mobile app.</p>
                                    </div>
                                </div>
                                <div className="navbar-mail-div text-center">
                                    <Link to="mailto:careers@getsixpence.com" className="font-neuemontrealregular text-[18px] leading-[120%] text-black hover:opacity-50 hover:duration-300">careers@getsixpence.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
            {step === 'step-header-4' && (
                <header className={`header bg-[#EEEAE8]`}>
                    <div className="container">
                        <div className="header-inside flex justify-between items-center pt-[58px] pb-[10px] step-header-2">
                            <Link
                                to="/"
                                className="navbar-logo-link inline-block mr-[15px]"
                                onClick={handleStepClose}
                            >
                                 <h1 className="font-neuemontrealregular text-[22px] leading-[120%]">SIXPENCE</h1>
                               </Link>
                            <div
                                className={`navbar-toggler cursor-pointer text-[30px] text-[#0061aa] w-[36px] h-[36px] rounded-full inline-flex justify-center items-center bg-[#1E1E1E]`}
                                onClick={handleStepClose}
                            >
                                <img src={closeIcon} alt="Close" />
                            </div>
                            <div
                                className={`navbar w-[101%] fixed top-[95px] transition-all pt-[50px] pb-[29px] px-[30px] height-90 z-[99] bg-[#EEEAE8] max-w-[640px] flex flex-col justify-between right-[unset] left-[50%] translate-x-[-50%] overflow-y-auto`}
                            >
                                <div className="wh-question-start">
                                    <div className="wh-question-title mb-[57px] min-h-[74px]">
                                        <h2 className="font-sdrregular text-[28px] leading-[37px] text-black">When is the mobile app coming out ?</h2>
                                    </div>
                                    <div className="wh-question-text mb-[20px]">
                                        <p className="font-sdrregular text-[18px] leading-[24px] text-black">The mobile app will be out in October of 2024. More social, more rewarding and more fun to use.</p>
                                    </div>
                                </div>
                                <div className="navbar-mail-div text-center">
                                    <Link to="mailto:careers@getsixpence.com" className="font-neuemontrealregular text-[18px] leading-[120%] text-black hover:opacity-50 hover:duration-300">careers@getsixpence.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    );
};

export default Header;