import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import avatar5 from "../../assets/images/avatar5.svg";
import supabase from "../../supabaseClient"; // Adjust the import path
import { useUser } from "../context/userContext"; // Assuming you have a user context
import { TailSpin } from "react-loader-spinner";
import { RandomAvatar } from "react-random-avatars";
import ArrowDown from "../../assets/images/ArrowDown.svg";
import Arros from "../../assets/images/Arros.svg";

export default function LikedPosts({ setColor }) {
  const [userPosts, setUserPosts] = useState([]);
  const [loader, setLoader] = useState(true);
  const { userData, userId: loggedInUserId, data } = useUser();
  const { userId: routeUserId } = useParams();
  const EachUserId=useParams()
  const [downvote, setDownvote] = useState(false);
  const userId = routeUserId || loggedInUserId;
  const navigate = useNavigate();
  const [disappearingCards, setDisappearingCards] = useState([]);

  const fetchCasts = async (userId) => {
    setLoader(true);

    // Step 1: Fetch upvoted posts from the Votes table
    const { data: votesData, error: votesError } = await supabase
      .from("Votes")
      .select("*")
      .eq("user_id", userId)
      .eq("upvotes", 1)
      .order("created_at", { ascending: false });


    if (votesError) {
      console.error("Error fetching votes:", votesError);
      setLoader(false);
      return;
    }

    // Step 2: Extract post_ids from the votesData
    const postIds = votesData.map((vote) => vote.post_id);

    if (postIds.length === 0) {
      setUserPosts([]);
      setTimeout(() => {
        setLoader(false);
      }, 500);
      return;
    }

    // Step 3: Fetch the posts from the Posts table
    if (isEmpty(EachUserId)){
    const { data: postsData, error: postsError } = await supabase
      .from("Posts")
      .select("*")
      .in("id", postIds)
      .order("created_at", { ascending: false });

      setUserPosts(postsData);
      if (postsError) {
        console.error("Error fetching posts:", postsError);
        setLoader(false);
        return;
      }
    } else {
    const { data, error: postsError } = await supabase
      .from('Posts')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

      setUserPosts(data);
      setColor(false)

      if (postsError) {
        console.error("Error fetching posts:", postsError);
        setLoader(false);
        return;
      }
}

    // Step 4: Set the fetched posts into the state
    // setUserPosts(postsData);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  useEffect(() => {
    if (userId) {
      fetchCasts(userId);
    }
  }, [userId]);

  async function upvotePost(postId, creatorId) {
    if (userData === "") {
      navigate("/post");
    }

    try {
      const { data: existingVote, error: existingVoteError } = await supabase
        .from("Votes")
        .select("*")
        .eq("post_id", postId)
        .eq("user_id", userData?.user.id)
        .single();

      if (existingVoteError && existingVoteError.code !== "PGRST116") {
        console.error("Error fetching existing vote:", existingVoteError);
        return;
      }

      if (existingVote) {
        if (existingVote.downvotes > 0) {
          // Remove the downvote and add an upvote
          await supabase
            .from("Votes")
            .update({ downvotes: 0, upvotes: 1 })
            .eq("id", existingVote.id);
        } else {
          console.log("User already upvoted this post");
          return;
        }
      } else {
        await supabase.from("Votes").insert([
          {
            post_id: postId,
            user_id: userData?.user.id,
            upvotes: 1,
            downvotes: 0,
            creator_id: creatorId,
          },
        ]);
      }
    } catch (err) {
      console.error("Error upvoting post:", err);
    }
  }

  async function downvotePost(postId, creatorId) {
    try {
      setDownvote(true)
      setDisappearingCards((prev) => [...prev, postId]);

      const { data: existingVote, error: existingVoteError } = await supabase
        .from("Votes")
        .select("*")
        .eq("post_id", postId)
        .eq("user_id", userData?.user.id)
        .single();

      if (existingVoteError && existingVoteError.code !== "PGRST116") {
        console.error("Error fetching existing vote:", existingVoteError);
        return;
      }

      if (existingVote) {
        if (existingVote.upvotes > 0) {
          // Remove the upvote and add a downvote
          await supabase
            .from("Votes")
            .update({ upvotes: 0, downvotes: 1 })
            .eq("id", existingVote.id);
        } else {
          console.log("User already downvoted this post");
          return;
        }
      } else {
        await supabase.from("Votes").insert([
          {
            post_id: postId,
            user_id: userData?.user.id,
            upvotes: 0,
            downvotes: 1,
            creator_id: creatorId,
          },
        ]);
      }
      setDownvote(false)
    } catch (err) {
      console.error("Error downvoting post:", err);
    }
  }

  const handleAnimationEnd = (cardId) => {
    setDisappearingCards((prev) => prev.filter((id) => id !== cardId));
    setUserPosts((prev) => prev.filter((post) => post.id !== cardId));
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  return (
    <div className="relative mt-[60px]">
      {loader ? (
        <div className="flex h-[50vh] justify-center items-center">
          <TailSpin height="40" width="90" color="#ffffff" />
        </div>
      ) : userPosts.length > 0 || data.length > 0 ? (
        isEmpty(EachUserId) ? (
            <div className="flex flex-col gap-5">
              {userPosts.map((card) => (
                <div key={card.id} className={`mb-[28px] ${disappearingCards.includes(card.id) ? "animate-out" : ""}`}
                  onAnimationEnd={() => handleAnimationEnd(card.id)}>
                  <div className="relative block bg-black border-[1px] border-[#CAA] rounded-[20px] p-[16px_16px_24px_16px]">
                    <div className="flex justify-between items-center mb-[14px]">
                      <Link to={`/profile/${card?.user_id}`}>
                        <div className="flex gap-[8px] items-center">
                          <div className="bg-[#FFC1C1] rounded-full h-[26px] w-[26px] flex items-center justify-center">
                            <RandomAvatar name={card.username} size={24} />
                          </div>
                          <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                            {card.username}
                          </h4>
                        </div>
                      </Link>
                      <p className="text-[#ffffff59] text-[12px] font-plusJakartaMedium leading-[120%]">
                        {card.time}
                      </p>
                    </div>
                    <div className="mb-[24px] last:mb-[0]">
                      <h5 className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] mb-[11px]">
                        {card.title}
                      </h5>
                      <p className="text-white text-[12px] font-plusJakartaMedium leading-[120%] mb-[24px]">
                        {card.description}
                      </p>
                      {card.image && (
                        <div className="rounded-[10px] overflow-hidden">
                          <img src={card.image} alt="dish" className="w-full mx-auto rounded-[10px] object-cover" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full mt-[-15px] px-[16px] relative flex justify-between items-center">
                    <div className="flex flex-row gap-[24px]">
                      <button onClick={() => upvotePost(card.id, card.user_id)} className="group">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.1692 21.4154H6.83069L6.83069 12.9231H5.16915L5.723 5.53848L6.83069 5.35387V4.06156H17.1692V5.35387L18.4615 5.53848L18.8307 12.9231H17.1692V21.4154Z"
                            fill="#3CBA41" />
                          <path className="group-hover:fill-[#FFC00C]"
                            d="M-1.16564e-06 13.3333L-9.32509e-07 10.6667L1.33333 10.6667L1.33333 9.33333L2.66667 9.33333L2.66667 8L4 8L4 6.66667L5.33333 6.66667L5.33333 5.33333L6.66667 5.33333L6.66667 4L8 4L8 2.66667L9.33333 2.66667L9.33333 1.33333L10.6667 1.33333L10.6667 9.3251e-07L13.3333 1.16564e-06L13.3333 1.33333L14.6667 1.33333L14.6667 2.66667L16 2.66667L16 4L17.3333 4L17.3333 5.33333L18.6667 5.33333L18.6667 6.66667L20 6.66667L20 8L21.3333 8L21.3333 9.33333L22.6667 9.33333L22.6667 10.6667L24 10.6667L24 13.3333L17.3333 13.3333L17.3333 24L6.66666 24L6.66666 13.3333L-1.16564e-06 13.3333ZM5.33333 10.6667L9.33333 10.6667L9.33333 21.3333L14.6667 21.3333L14.6667 10.6667L18.6667 10.6667L18.6667 9.33333L17.3333 9.33333L17.3333 8L16 8L16 6.66667L14.6667 6.66667L14.6667 5.33333L13.3333 5.33333L13.3333 4L10.6667 4L10.6667 5.33333L9.33333 5.33333L9.33333 6.66667L8 6.66667L8 8L6.66667 8L6.66666 9.33333L5.33333 9.33333L5.33333 10.6667Z"
                            fill="white" />
                        </svg>
                      </button>
                      <button onClick={() => downvotePost(card.id, card.user_id)}>
                        <img src={ArrowDown} alt="ArrowDown" className="h-[24px] w-[24px] hover:opacity-[0.7]" />
                      </button>
                    </div>
                    {card.product_url && card.product_url.trim() !== '' &&
                      <div
                        className="rounded-full bg-white border-[1px] border-[#CAA] h-[30px] w-[30px] p-1 flex items-center justify-center">
                        <button onClick={() => {
                          let url = card.product_url.trim();

                          // Remove any unwanted local paths or characters
                          url = url.replace(/^(http:\/\/|https:\/\/|www\.)/, ''); // Remove protocol and www if present
                          if (!/^http/.test(url)) {
                            url = 'http://' + url; // Add http if not present
                          }

                          // Regular expression to check if the URL is valid
                          const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);

                          if (isValidUrl) {
                            // Open the URL in a new tab
                            window.open(url, '_blank');
                          } else {
                            // Show an alert if the URL is not valid
                            alert('This is not a valid URL');
                          }
                        }}
                        >
                          <img src={Arros} alt="arros" className="h-[24px] w-[24px]" />
                        </button>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>
        ) : (
              <div className="flex flex-col gap-5">
                {data.map((card) => (
                  <div key={card.id} className={`mb-[28px] ${disappearingCards.includes(card.id) ? "animate-out" : ""}`}
                    onAnimationEnd={() => handleAnimationEnd(card.id)}>
                    <div className="relative block bg-black border-[1px] border-[#CAA] rounded-[20px] p-[16px_16px_24px_16px]">
                      <div className="flex justify-between items-center mb-[14px]">
                        <div className="flex gap-[8px] items-center">
                          <div className="bg-[#FFC1C1] rounded-full h-[26px] w-[26px] flex items-center justify-center">
                            <RandomAvatar name={card.username} size={24} />
                          </div>
                          <h4 className="font-plusJakartaSemiBold text-[14px] font-[120%] text-white">
                            {card.username}
                          </h4>
                        </div>
                        <p className="text-[#ffffff59] text-[12px] font-plusJakartaMedium leading-[120%]">
                          {card.time}
                        </p>
                      </div>
                      <div className="mb-[24px] last:mb-[0]">
                        <h5 className="text-[#FF8181] text-[12px] font-plusJakartaMedium leading-[120%] mb-[11px]">
                          {card.title}
                        </h5>
                        <p className="text-white text-[12px] font-plusJakartaMedium leading-[120%] mb-[24px]">
                          {card.description}
                        </p>
                        {card.image && (
                          <div className="rounded-[10px] overflow-hidden">
                            <img src={card.image} alt="dish" className="w-full mx-auto rounded-[10px] object-cover" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full mt-[-15px] px-[16px] relative flex justify-between items-center">
                      <div className="flex flex-row gap-[24px]">
                        <button onClick={() => upvotePost(card.id, card.user_id)} className="group">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.1692 21.4154H6.83069L6.83069 12.9231H5.16915L5.723 5.53848L6.83069 5.35387V4.06156H17.1692V5.35387L18.4615 5.53848L18.8307 12.9231H17.1692V21.4154Z"
                              fill="#3CBA41" />
                            <path className="group-hover:fill-[#FFC00C]"
                              d="M-1.16564e-06 13.3333L-9.32509e-07 10.6667L1.33333 10.6667L1.33333 9.33333L2.66667 9.33333L2.66667 8L4 8L4 6.66667L5.33333 6.66667L5.33333 5.33333L6.66667 5.33333L6.66667 4L8 4L8 2.66667L9.33333 2.66667L9.33333 1.33333L10.6667 1.33333L10.6667 9.3251e-07L13.3333 1.16564e-06L13.3333 1.33333L14.6667 1.33333L14.6667 2.66667L16 2.66667L16 4L17.3333 4L17.3333 5.33333L18.6667 5.33333L18.6667 6.66667L20 6.66667L20 8L21.3333 8L21.3333 9.33333L22.6667 9.33333L22.6667 10.6667L24 10.6667L24 13.3333L17.3333 13.3333L17.3333 24L6.66666 24L6.66666 13.3333L-1.16564e-06 13.3333ZM5.33333 10.6667L9.33333 10.6667L9.33333 21.3333L14.6667 21.3333L14.6667 10.6667L18.6667 10.6667L18.6667 9.33333L17.3333 9.33333L17.3333 8L16 8L16 6.66667L14.6667 6.66667L14.6667 5.33333L13.3333 5.33333L13.3333 4L10.6667 4L10.6667 5.33333L9.33333 5.33333L9.33333 6.66667L8 6.66667L8 8L6.66667 8L6.66666 9.33333L5.33333 9.33333L5.33333 10.6667Z"
                              fill="white" />
                          </svg>
                        </button>
                        <button onClick={() => downvotePost(card.id, card.user_id)}>
                          <img src={ArrowDown} alt="ArrowDown" className="h-[24px] w-[24px] hover:opacity-[0.7]" />
                        </button>
                      </div>
                      {card.product_url && card.product_url.trim() !== '' &&
                        <div
                          className="rounded-full bg-white border-[1px] border-[#CAA] h-[30px] w-[30px] p-1 flex items-center justify-center">
                          <button onClick={() => {
                            let url = card.product_url.trim();

                            // Remove any unwanted local paths or characters
                            url = url.replace(/^(http:\/\/|https:\/\/|www\.)/, ''); // Remove protocol and www if present
                            if (!/^http/.test(url)) {
                              url = 'http://' + url; // Add http if not present
                            }

                            // Regular expression to check if the URL is valid
                            const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(url);

                            if (isValidUrl) {
                              // Open the URL in a new tab
                              window.open(url, '_blank');
                            } else {
                              // Show an alert if the URL is not valid
                              alert('This is not a valid URL');
                            }
                          }}
                          >
                            <img src={Arros} alt="arros" className="h-[24px] w-[24px]" />
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
        )
      ) : (
        <div className="flex justify-center h-[70vh] items-center">
          <h2 className="text-[36px] text-white font-sdrregular text-center mb-1">No Liked Posts....</h2>
        </div>
      )}
    </div>
  );
}
