import React from 'react'
import Earth from "../../assets/images/Earth.svg";
import DollarRed from "../../assets/images/DollarRed.svg";
import { Link, useLocation } from 'react-router-dom';
import EarthRed from "../../assets/images/EarthRed.svg";
import Dollar from "../../assets/images/Dollar.svg";
import { useUser } from "../../components/context/userContext";
export default function Twobtn() {
  const { userData } = useUser();
  const location = useLocation();
  const isDiscoverActive = location.pathname.startsWith('/discover');
  const isPostActive = location.pathname.startsWith('/post');
  return (
    <>
      <div className="fixed w-full max-w-[640px] mx-auto !bottom-[30px] z-[99] bg-[#1e1e1e00]">
        <div className="mx-auto flex justify-between items-center w-full max-w-[199px] h-[56px] relative z-[999] px-[25px] py-[13px] bg-black backdrop-blur-[0.1339549869298935px] rounded-[40px] border-[2px] border-solid border-white">
          <Link to='/discover' className='relative z-[999]'>
            {isDiscoverActive ? <img src={EarthRed} alt="EarthRed" className="h-[30px] w-[30px] relative z-[999]" />
            : <img src={Earth} alt="Earth" className="h-[30px] w-[30px] relative z-[999]" />}
          </Link>

          {userData === null ? (
            <Link to='/post' className='relative z-[999]'>
              {isPostActive ? <img src={DollarRed} alt="DollarRed" className="h-[30px] w-[30px] relative z-[999]" />
                : <img src={Dollar} alt="Dollar" className="h-[30px] w-[30px] relative z-[999]" />}
            </Link>
          ) : (
            <Link to='/post/manage_post/managepostbtnlogin' className='relative z-[999]'>
              {isPostActive ? (
                <img src={DollarRed} alt="DollarRed" className="h-[30px] w-[30px] relative z-[999]" />
              ) : (
                <img src={Dollar} alt="Dollar" className="h-[30px] w-[30px] relative z-[999]" />
              )}
            </Link>
          )}
        </div>
      </div>
    </>
  )
}
