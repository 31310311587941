import React from 'react';
import Layout from '../../pages/Layout';

export default function ShareSomeThing() {
  return (
    <>
      <div className="my-auto flex flex-col justify-center items-center fixed w-[100%] h-[70%]">
        <h2 className="text-[36px] text-white font-sdrregular text-center mb-1">
          <span className="font-sdrregular" >Share</span> <span className="font-neuemontrealregular">something....</span>
        </h2>
        <p className="font-neuemontrealregular text-[16px] text-white text-center">
          to create profile
        </p>
        <div className="flex justify-center items-center">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
          className="w-[20%] h-[35%] absolute left-[58%] rotate-[269deg]  bottom-0">
          <g fill="none" stroke="#FF8181" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="m8 5l-5 5l5 5" />
            <path d="M3 10h8c5.523 0 10 4.477 10 10v1" />
          </g>
        </svg>
      </div>
    </>
  );
}
