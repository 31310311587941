import React from "react";
import { Link } from "react-router-dom";
import ArrowBack from "../../assets/images/ArrowBack.svg";
import Layout from "../../pages/Layout";
import avatar4 from "../../assets/images/avatar4.svg";
import avatar3 from "../../assets/images/avatar3.svg";
import avatar2 from "../../assets/images/avatar2.svg";
import avatar1 from "../../assets/images/avatar1.svg";

export default function ProfileFollowerList() {
    const followers = [
        { id: 1, name: "Kovasis", avatar: avatar1, bg: "#9D3BFF" },
        { id: 2, name: "Pulasic", avatar: avatar2, bg: "#FFFFFF" },
        { id: 3, name: "Mitrovic", avatar: avatar3, bg: "#FFC1C1" },
        { id: 4, name: "Vidic", avatar: avatar4, bg: "#FAEDDC" },
      ];
  return (
    <Layout>
      <div className="relative pb-[131px]">
        <div className="container">
          <Link to="/profile" className="inline-block mb-[40px]">
            <div className="flex flex-row items-center gap-5">
              <img
                src={ArrowBack}
                alt="question"
                className="h-[30px] w-[30px]"
              />
              <span className="font-plusJakartaSemiBold text-white leading-[120%]">
                follwing
              </span>
            </div>
          </Link>
          <div className="flex flex-col gap-[36px]">
          {followers.map((follower) => (
              <div key={follower.id} className="flex flex-row items-center gap-5">
                <div className={`rounded-full flex justify-center items-center`} style={{backgroundColor:`${follower.bg}`}}>
                  <img
                    src={follower.avatar}
                    alt={follower.name}
                    className="h-[32px] w-[32px]"
                  />
                </div>
                <h2 className="text-white text-[22px] leading-[120%] font-nohemimedium">
                  {follower.name}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
