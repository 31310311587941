import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../../supabaseClient';

// Create a context with a default value
const UserContext = createContext({
  userData: null,
  setUserData: () => {},
  username: '',
  userId: '',
});

// Provider component
export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [username, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [userPosts, setUserPosts] = useState('')
  const [data, setData] = useState('')



  useEffect(() => {
    const fetchUser = async () => {
      const { data: userData, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }
      setUserData(userData);
      setUserId(userData.user.id);
      setUserName(userData?.user?.identities[0]?.identity_data?.name || '');
    };

    fetchUser();

  }, []);

  useEffect(()=>{
    const fetchUserPosts = async () => {
      const { data, error } = await supabase
        .from('Posts')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });
  
      if (error) {
        console.error('Error fetching casts:', error);
      } else {
        // console.log('data inside ap', data)
        setUserPosts(data)
        setData(data)
      }
    };
    fetchUserPosts()
  },[userId]);

  return (
    <UserContext.Provider value={{ userData, setUserData, username, userId, userPosts, data }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);