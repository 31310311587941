import React from "react";
import RewardPage from "../../components/RewardPage";
import Layout from "../Layout";
import { Outlet, useLocation } from "react-router";

export default function Reward() {
  const location = useLocation();

  return (
    <Layout>
      {/* Conditionally render content based on the current route */}
      {location.pathname === "/reward" ? (
        <>
          {/* Main Post content here */}
          <RewardPage />
          {/* Any other components or content for the main Post page */}
        </>
      ) : (
        <Outlet />
      )}
    </Layout>
  );
}
